import { createContext } from 'react'
import type { client } from './types/client'
import { account } from './types/account'
import { change } from './types/change'
import { permission } from './types/permission'
import { changeNotification } from './types/changeNotification'

export const ClientsContext = createContext<client[]>([]) // all accounts
export const AccountsContext = createContext<account[]>([]) // all clients
export const ChangesContext = createContext<change[]>([]) // all changes
export const UserCommsContext = createContext<number[]>([]) // all comms from all clients assigned to account. 
export const UserClientIDsContext = createContext<string[]>([]) // all the ids of clients assigned to the current user.
export const UserContactIDsFromClientsContext = createContext<string[]>([]) // all the ids of contacts from client assigned to the current user.
export const CurrentUserContext = createContext<account>({
  id: '',
  'Cell Phone': 'string',
  City: '',
  Ext: '',
  State: '',
  company: '',
  email: '',
  internal: true,
  name: '',
  phone: '',
  title: '',
}) // id of current user
export const PermissionsContext = createContext<permission>({
  // permissions should never have any undefined values.
  title: 'Default',
  add_accounts: false,
  edit_accounts: false,
  edit_my_clients_accounts: false,
  edit_current_user_account: false,
  add_clients: false,
  edit_all_clients: false,
  edit_assigned_clients: false,
  view_tiers: false,
  edit_payments: false,
  view_payments: false,
  view_my_client_payments: false,
  edit_all_roles: false,
  edit_my_client_roles: false,
  edit_all_contacts: false,
  edit_my_client_contacts: false,
  view_settings: false,
  edit_presets: false,
  edit_marketing: false,
  view_marketing: false,
}) // what roles the user has.

// these should be updated on an app level once, and this should allow for only a couple pulls per session per day.
