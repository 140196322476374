import { useEffect, useState } from 'react'
import FullPageLoading from './pages/public/FullPageLoading'

export default function LoadingOverlay() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }
  })
  return (
    <div>
      {loading && (
        <div className="z-50">
          <FullPageLoading />
        </div>
      )}
    </div>
  )
}
