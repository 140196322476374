import { client } from "../types/client";

export default function collectContactIdsFromClient(client: client) {

  var contact_ids:string[] = []

  const contacts = client['contacts']

  Object.keys(contacts).forEach((title)=>{
    contact_ids = contact_ids.concat(contacts[title])
  })
  console.log(contact_ids)
  return contact_ids
}