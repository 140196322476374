import { client } from "../types/client";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";

export default async function updateClientActiveField(clients: client[]) {
  // standard date format is MM/DD/YYYY: string
  // the active field is updated here instead of being accessible to users.

  function createDateFromString(date_string: string) {
    const date_array = date_string.split("/");
    const year = Number(date_array[2]);
    const month = Number(date_array[0]) - 1;
    const day = Number(date_array[1]); // months in javascript's date start at 0
    const date = new Date(year, month, day);
    return date;
  }

  const today = new Date();

  for (let i = 0; i < clients.length; i++) {
    const client = clients[i];
    var active = false;
    var start_found = false;
    var end_found = false;
    var start_date = new Date();
    var end_date = new Date();

    if (client.start_date !== undefined && client.start_date !== "") {
      start_date = createDateFromString(client.start_date);
      start_found = true;
    } else {
      start_found = false;
    }
    if (client.end_date !== undefined && client.end_date !== "") {
      end_date = createDateFromString(client.end_date);
      end_found = true;
    } else {
      end_found = false;
    }

    if (start_found) {
      if (start_date < today) {
        if (end_found) {
          if (end_date > today) {
            active = true;
          } else {
            active = false;
          }
        } else {
          active = true;
        }
      } else {
        active = false;
      }
    } else {
      active = false;
    }

    // do something with active if not matching client.
    if (client.active !== active) {
      await setDoc(
        doc(db, "clients", client.id),
        {
          active: active,
        },
        { merge: true },
      );
    }
  }
}
