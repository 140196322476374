import { doc, getDoc, setDoc } from 'firebase/firestore'
import hash from 'object-hash'
import { db } from '../firebase'

async function refreshIFMisaligned(type: "accounts" | "clients", current_set, reloadFn: Function) {
  const current_hash = hash(current_set)
  console.log("Current Hash: ", current_hash)
  const latest_hash_doc = await getDoc(doc(db, "hashes", type))
  if (latest_hash_doc.exists()) {
    const latest_hash = latest_hash_doc.data().current_hash
    console.log(latest_hash)
    if (current_hash !== latest_hash) {
      await reloadFn({reload_type: type})
    }
  }
}

async function setHash(type: 'accounts' | 'clients', new_obj) {
  const new_hash = hash(new_obj)
  await setDoc(doc(db, "hashes", type), {current_hash: new_hash})
}

export {refreshIFMisaligned, setHash}