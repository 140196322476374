import {
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from '@material-tailwind/react'
import { useContext, useEffect, useState } from 'react'
import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore'
import { db } from '../../firebase'
import { AccountsContext, PermissionsContext } from '../../contexts'
import { account } from '../../types/account'
import createAccount from '../../functions/createAccount'
import PermissionsOverivew from './PermissionsOverview'
import PermissionsAccessManagement from './PermissionsAccessManagement'

export default function PermissionsTab(props: { reloadData: Function }) {
  const permissions = useContext(PermissionsContext)
  const accounts = useContext(AccountsContext)
  const [permissions_presets, setPermissions] = useState({})
  const [allPermissions, setAllPermissions] = useState({})
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([])
  const [selectedPerRole, setSelectedPerRole] = useState('')
  const [query, setQuery] = useState('')
  const [choosingPermissions, setChoosingPermissions] = useState(false)
  const [acctKey, setAcctKey] = useState('')
  async function getPermissions() {
    await getDoc(doc(db, 'settings', 'permissions_file_templates')).then(
      (doc) => {
        if (doc.exists()) {
          const data = doc.data()
          setPermissions(data)
        } else {
          alert(
            'There was an error loading permissions. Check internet contection and try again.'
          )
        }
      }
    )
  }
  async function getRoleAssignments() {
    await getDocs(collection(db, 'permissions')).then((querySnapshot) => {
      var temp = {}
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        if (data !== undefined) {
          temp[doc.id] = data
        }
      })
      setAllPermissions(temp)
    })
  }
  function exitWithoutSaving() {
    setSelectedAccounts([])
    setSelectedPerRole('')
    setChoosingPermissions(false)
    handleDialog()
  }
  function handleDialog() {
    setDialogOpen(!dialogOpen)
  }
  function handleQuery(e) {
    setQuery(e.target.value)
  }
  const reloadAccountsTable = () => {
    setAcctKey(String(Math.round(Math.random() * 100000)))
  }
  function toggleAccountSelection(id: string) {
    var new_selectedAccounts_object: string[] = selectedAccounts
    if (!selectedAccounts.includes(id)) {
      new_selectedAccounts_object.push(id)
      setSelectedAccounts(new_selectedAccounts_object)
      reloadAccountsTable()
    } else {
      var current_accounts: string[] = selectedAccounts
      var final_accounts: string[] = []
      for (let index in current_accounts) {
        if (current_accounts[index] !== id) {
          final_accounts.push(current_accounts[index])
        }
      }
      new_selectedAccounts_object = final_accounts
      setSelectedAccounts(new_selectedAccounts_object)
    }
  }
  function search() {
    var array_of_accounts: account[] = []
    for (let key in accounts) {
      if (accounts[key].UID !== undefined) {
        array_of_accounts.push(accounts[key])
      }
    }
    return array_of_accounts.filter((account) =>
      account.name.toString().toLowerCase().includes(query.toLowerCase())
    )
  }
  async function saveAccountsForNewPermissions() {
    for (let i in selectedAccounts) {
      try {
        var UID
        const permissions_title = selectedPerRole
        var permissions_content = permissions_presets[selectedPerRole]
        permissions_content['title'] = permissions_title
        for (let j in accounts) {
          if (accounts[j].id === selectedAccounts[i]) {
            UID = accounts[j].UID
            console.log('Account matched with UID')
            await setDoc(doc(db, 'permissions', UID), permissions_content).then(
              () => {
                setFirstLoad(true)
              }
            )
          }
        }
      } catch (err) {
        console.log(err)
        alert(
          'Error saving new permissions for Account ID: ' +
            selectedAccounts[i] +
            '\nProceeding with next account(s).'
        )
      }
    }
    exitWithoutSaving()
  }

  const [addAccount, setAddAccount] = useState(false)

  const [accountQuery, setAccountQuery] = useState('')
  async function addSelectedAccountToPermissions(account_id) {
    if (account_id !== undefined && account_id !== '') {
      await getDoc(doc(db, 'accounts', account_id)).then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data()
          if (data.UID !== undefined && data.UID !== '') {
            getDoc(doc(db, 'permissions', data.UID)).then((docSnap2) => {
              if (docSnap2.exists()) {
                alert(
                  'Account already has permissions file! \nPlease add them using the Add / Edit Dialog.'
                )
              } else {
                setDoc(doc(db, 'permissions', data.UID), {
                  title: 'Default',
                })
                  .then(() => {
                    alert('Adding Permissions was successful!')
                    setTimeout(() => {
                      props.reloadData({reload_type: 'permissions'})
                    }, 1000)
                  })
                  .catch((err) => {
                    alert(
                      'There was an error saving the new permissions doc, please try again!'
                    )
                  })
              }
            })
          } else {
            const add_account_cred = window.confirm(
              'An Error Occured when adding account. This user has not been setup with a login yet or has yet to login for the first time. Would you like to issue them a login credential and proceed?'
            )
            if (add_account_cred) {
              var email_input = ''
              email_input = email_input + data.email
              email_input = email_input.replace(' ', '')

              createAccount({ email: email_input })
                .then((response) => {
                  const response_from_firebase_found = response[0]
                  const response_status = response[0]
                  if (!response_from_firebase_found) {
                    alert(
                      'Creating Account failed. Please try again after reloading the page.'
                    )
                  } else if (response_status) {
                    alert('Creating account was successful!')
                    setTimeout(() => {
                      props.reloadData({reload_type: 'permissions'})
                    }, 1000)
                  } else {
                    alert(
                      'Creating Account failed. Please try again after reloading the page.'
                    )
                  }
                })
                .catch((err) => {
                  console.log(err)
                  alert('Adding User Failed. Console logged the issue.')
                })
            }
          }
        } else {
          alert(
            'Could not find the selected account. Check your internet connection.'
          )
        }
      })
    } else {
      alert(
        'An Error Occurred when adding account. Please make sure you selected an option and try again.'
      )
    }
    setAccountQuery('')
    setAccountSearchOpen(false)
    setAddAccount(false)
  }
  const [accountSearchOpen, setAccountSearchOpen] = useState(false)
  function handleAccountSearch() {
    setAccountSearchOpen(!accountSearchOpen)
  }
  function searchAccount(accounts: account[]) {
    return accounts.filter((account) => {
      return account.name.toLowerCase().includes(accountQuery.toLowerCase())
    })
  }
  function permissionsDisplayFilter(permission_docs_ids: string[]) {
    var permission_levels_by_UID = {
      Organizer: [],
      Director: [],
      'Business Manager': [],
      Marketing: [],
      Admin: [],
      Default: [],
    }
    var uids_without_title: string[] = []
    for (let i = 0; i < permission_docs_ids.length; i++) {
      try {
        permission_levels_by_UID[
          allPermissions[permission_docs_ids[i]].title
        ].push(permission_docs_ids[i])
      } catch (err) {
        uids_without_title.push(permission_docs_ids[i])
      }
    }

    var final_array: string[] = []
    final_array.push(...permission_levels_by_UID['Organizer'])
    final_array.push(...permission_levels_by_UID['Director'])
    final_array.push(...permission_levels_by_UID['Business Manager'])
    final_array.push(...permission_levels_by_UID['Marketing'])
    final_array.push(...permission_levels_by_UID['Admin'])

    return final_array
  }
  function accounts_without_permissions(
    accounts: account[],
    all_permissions: {}
  ) {
    var final_account_array: account[] = []
    var array_of_ids_with_permissions = Object.keys(all_permissions)

    accounts.forEach((account) => {
      if (array_of_ids_with_permissions.includes(account.id) === false) {
        final_account_array.push(account)
      }
    })

    return final_account_array
  }
  const role_titles = [
    'Organizer',
    'VP',
    'Director',
    'Business Manager',
    'Marketing',
    'Admin',
    'Default',
  ]
  const permission_keys = [
    'add_accounts',
    'edit_accounts',
    'edit_my_clients_accounts',
    'edit_current_user_account',
    'add_clients',
    'edit_all_clients',
    'edit_assigned_clients',
    'view_tiers',
    'edit_payments',
    'view_payments',
    'view_my_client_payments',
    'edit_all_roles',
    'edit_my_client_roles',
    'edit_all_contacts',
    'edit_my_client_contacts',
    'view_settings',
    'edit_presets',
    'edit_marketing',
    'view_marketing',
  ]
  const permission_titles = [
    'Add Accounts',
    'Edit Accounts',
    "Edit My Clients' Accounts",
    'Edit Current User Account',
    'Add Clients',
    'Edit All Clients',
    'Edit Assigned Clients',
    'View Tiers',
    'Edit Payments',
    'View Payments',
    'View My Client Payments',
    'Edit All Roles',
    'Edit My Client Roles',
    'Edit All Contacts',
    'Edit My Client Contacts',
    'View Settings',
    'Edit Presets',
    'Edit Marketing',
    'View Marketing',
  ]

  const TABLE_HEAD = ['Name', 'Phone #', 'Email', 'Title', '']

  const [firstLoad, setFirstLoad] = useState(true)
  // eslint-disable-next-line
  useEffect(() => {
    if (firstLoad) {
      getPermissions()
      getRoleAssignments()
      setFirstLoad(false)
    }
  })
  return (
    <Tabs value={'overview'}>
      <TabsHeader placeholder={'.'}>
        <Tab placeholder={'.'} key={'overview'} value={'overview'}>
          <div>Overview</div>
        </Tab>
        <Tab placeholder={'.'} key={'manage_access'} value={'manage_access'}>
          <div>Manage Access</div>
        </Tab>
      </TabsHeader>
      <TabsBody placeholder={'.'}>
        <TabPanel value={'overview'}>
          <PermissionsOverivew />
        </TabPanel>
        <TabPanel value={'manage_access'}>
          <PermissionsAccessManagement reloadData={props.reloadData} />
        </TabPanel>
      </TabsBody>
    </Tabs>
  )
}
