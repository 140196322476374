import { account } from "../types/account";
import { client } from "../types/client";
import formatEmails from "./formatEmails";
import updateClientActiveField from "./updateClientActiveField";

export default async function upkeep(clients: client[], accounts:account[]) {
    // this is the base for all upkeep functions.
    // while google functions does support features that would be in some ways well suited to these upkeep functions, there is a reason to include them here. 
    // Deploying functions is quite time consuming and finicky and at the same time its quite difficult to emulate.
    // until the performance hit is serious I intend to continue to leverage the browser's resources until it poses issues.
    // in the long run I would like to ensure that old machines are opted out of some of these tasks since they are repetitive and could become heavy later on.

    // tl;dr:
    // This may be offloaded later and an argument for offloading them sooner is reasonable. However these are db upkeep functions.

    // ensures that the active field on all clients matches the status implied by their start and end dates. 
    
    try {
    await updateClientActiveField(clients);
    } catch (err) {
        console.log("Error on client active field.")
    }
    try {
    await formatEmails(accounts);
    } catch (err) {
        console.log("Error on removing spaces from email.")
    }
}