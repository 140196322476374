import { memo, useContext, useEffect, useState } from "react";
import { paymentDoc } from "../types/paymentDoc";
import { Typography } from "@material-tailwind/react";
import { ClientsContext } from "../contexts";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const PaymentBlock = memo(function PaymentBlock(props: {
  payment_doc: paymentDoc;
}) {
  const file_id = props.payment_doc.file_id;
  const payment_history = props.payment_doc;
  const payment_records = {
    budget_adjustments: props.payment_doc.budget_adjustments,
    budget_original: props.payment_doc.budget_original,
    payments_applied: props.payment_doc.payments_applied,
    payments_recieved: props.payment_doc.payments_recieved,
  };
  const payment_keys = [
    "budget_original",
    "budget_adjustments",
    "payments_recieved",
    "payments_applied",
  ];
  const payment_headers = [
    "Budget Original",
    "Budget Adjustments",
    "Payments Recieved",
    "Payments Applied",
  ];
  const clients = useContext(ClientsContext);
  const [clientFound, setClientFound] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientID, setClientID] = useState("");
  const current_year = new Date().getFullYear();
  const [currentYear, setCurrentYear] = useState(String(current_year));
  const monthsOfYear1 = ["Category", "Jan", "Feb", "Mar", "Apr", "May", "Jun"];
  const monthsOfYear2 = ["Category", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  function incrementYear(direction: "left" | "right") {
    if (direction === "left") {
      var yearNum = Number(currentYear);
      yearNum -= 1;
      setCurrentYear(String(yearNum));
    }
    if (direction === "right") {
      var yearNum = Number(currentYear);
      yearNum += 1;
      setCurrentYear(String(yearNum));
    }
  }
  function findClientName() {
    var client_name_temp = "";
    var client_id_temp = "";
    for (let i in clients) {
      if (clients[i].file_id === file_id) {
        client_name_temp = clients[i].name;
        client_id_temp = clients[i].id;
      }
    }
    if (client_name_temp === "") {
      client_name_temp = "(Unknown)";
    }
    setClientID(client_id_temp);
    setClientName(client_name_temp);
  }

  useEffect(() => {
    if (!clientFound) {
      findClientName();
      setClientFound(true);
    }
  });

  return (
    <div className="flex h-full w-full flex-col p-2">
      <div className="flex flex-row justify-between pb-2">
        <div className="justify-left flex h-full w-2/3 flex-col">
          <Typography
            placeholder={"."}
            variant="h5"
            className="h-full min-w-fit text-blue-800"
          >
            <Link to={"/view/client#" + clientID}>{clientName}</Link>
          </Typography>
          <Typography
            placeholder={"."}
            variant="paragraph"
            className=" h-full w-fit"
          >
            <strong>File ID:</strong> {file_id}
          </Typography>
        </div>
        <div className="flex h-fit w-fit flex-row">
          <ChevronLeftIcon
            className="h-6 w-6"
            onClick={() => {
              incrementYear("left");
            }}
          />
          <div className=" font-bold">{currentYear}</div>
          <ChevronRightIcon
            className="h-6 w-6"
            onClick={() => {
              incrementYear("right");
            }}
          />
        </div>
      </div>
      <div>
        <div>
          <table className="min-h-fit w-full min-w-fit rounded-md border">
            <thead>
              <tr>
                {monthsOfYear1.map((month, index) => {
                  return (
                    <th
                      className="border border-b border-blue-gray-100 bg-blue-gray-50 p-2"
                      key={"month_header_entry" + index}
                    >
                      <Typography
                        placeholder="."
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none text-blue-800 opacity-70"
                      >
                        {month}
                      </Typography>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="w-full">
              {payment_keys.map((key, row_index) => {
                return (
                  <>
                    {payment_history[key] !== undefined ? (
                      <tr className="w-full">
                        {monthsOfYear1.map((month, index) => {
                          var payments;
                          try {
                            payments = payment_history[key][currentYear];
                            var dollar_amount = payments[month];
                            const index_of_decimal = dollar_amount.indexOf(".");
                            if (index_of_decimal > 0) {
                              dollar_amount = Number(
                                Number(dollar_amount).toPrecision(
                                  Number(2 + index_of_decimal),
                                ),
                              );
                            }
                          } catch (err) {
                            payments = undefined;
                          }
                          if (index !== 0) {
                            return (
                              <>
                                {payments !== undefined ? (
                                  <td
                                    key={"month_data_entry_" + index}
                                    className="border p-2"
                                  >
                                    <Typography
                                      placeholder="."
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      ${dollar_amount}
                                    </Typography>
                                  </td>
                                ) : (
                                  <td
                                    key={"month_data_entry_" + index}
                                    className="border p-2"
                                  >
                                    <Typography
                                      placeholder="."
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      N/A
                                    </Typography>
                                  </td>
                                )}
                              </>
                            );
                          } else {
                            const row_title = payment_headers[row_index];
                            return (
                              <td
                                key={"month_data_entry_" + index}
                                className="p-2"
                              >
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {row_title}
                                </Typography>
                              </td>
                            );
                          }
                        })}
                      </tr>
                    ) : (
                      <tr className="w-full">
                        {monthsOfYear1.map((month, index) => {
                          if (index !== 0) {
                            return (
                              <td
                                key={"month_data_entry_" + index}
                                className="p-2"
                              >
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  N/A
                                </Typography>
                              </td>
                            );
                          } else {
                            const row_title = payment_headers[row_index];
                            return (
                              <td
                                key={"month_data_entry_" + index}
                                className="p-2"
                              >
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {row_title}
                                </Typography>
                              </td>
                            );
                          }
                        })}
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>
          <table className="min-h-fit w-full min-w-fit rounded-md border">
            <thead>
              <tr>
                {monthsOfYear2.map((month, index) => {
                  return (
                    <th
                      className="border border-b border-blue-gray-100 bg-blue-gray-50 p-2"
                      key={"month_header_entry" + index}
                    >
                      <Typography
                        placeholder="."
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none text-blue-800 opacity-70"
                      >
                        {month}
                      </Typography>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="w-full">
              {payment_keys.map((key, row_index) => {
                return (
                  <>
                    {payment_history[key] !== undefined ? (
                      <tr className="w-full">
                        {monthsOfYear2.map((month, index) => {
                          var payments;
                          try {
                            payments = payment_history[key][currentYear];
                            var dollar_amount = payments[month];
                            const index_of_decimal = dollar_amount.indexOf(".");
                            if (index_of_decimal > 0) {
                              dollar_amount = Number(
                                Number(dollar_amount).toPrecision(
                                  Number(2 + index_of_decimal),
                                ),
                              );
                            }
                          } catch (err) {
                            payments = undefined;
                          }
                          if (index !== 0) {
                            return (
                              <>
                                {payments !== undefined ? (
                                  <td
                                    key={"month_data_entry_" + index}
                                    className="border p-2"
                                  >
                                    <Typography
                                      placeholder="."
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      ${dollar_amount}
                                    </Typography>
                                  </td>
                                ) : (
                                  <td
                                    key={"month_data_entry_" + index}
                                    className="border p-2"
                                  >
                                    <Typography
                                      placeholder="."
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      N/A
                                    </Typography>
                                  </td>
                                )}
                              </>
                            );
                          } else {
                            const row_title = payment_headers[row_index];
                            return (
                              <td
                                key={"month_data_entry_" + index}
                                className="p-2"
                              >
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {row_title}
                                </Typography>
                              </td>
                            );
                          }
                        })}
                      </tr>
                    ) : (
                      <tr className="w-full">
                        {monthsOfYear2.map((month, index) => {
                          if (index !== 0) {
                            return (
                              <td
                                key={"month_data_entry_" + index}
                                className="p-2"
                              >
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  N/A
                                </Typography>
                              </td>
                            );
                          } else {
                            const row_title = payment_headers[row_index];
                            return (
                              <td
                                key={"month_data_entry_" + index}
                                className="p-2"
                              >
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {row_title}
                                </Typography>
                              </td>
                            );
                          }
                        })}
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

export default PaymentBlock;
