import {
  Button,
  Card,
  Checkbox,
  Chip,
  Tooltip,
  Typography,
} from '@material-tailwind/react'
import StandardLayout from '../components/StandardLayout'
import { useContext, useEffect, useState } from 'react'
import { CurrentUserContext } from '../contexts'
import { account } from '../types/account'
import { addDoc, collection, doc, setDoc } from 'firebase/firestore'
import { db } from '../firebase'
import { Navigate } from 'react-router'
import { preferencesObj } from '../types/preferencesObj'
import Loading from '../components/Loading'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

export default function NotificationPreferences(props: {
  reloadData: Function
}) {
  const [firstLoad, setFirstLoad] = useState(true)
  const account: account = useContext(CurrentUserContext)

  const [currentPreferences, setCurrentPreferences] = useState<preferencesObj>({
    my_account: true,
    my_assignment: true,
    my_client_info: true,
    my_client_role: true,
    my_client_contact: true,
    my_client_additional_info: true,
    my_client_upc_list: true,
  })
  const [startingPref, setStartingPref] = useState<preferencesObj>({
    my_account: true,
    my_assignment: true,
    my_client_info: true,
    my_client_role: true,
    my_client_contact: true,
    my_client_additional_info: true,
    my_client_upc_list: true,
  })

  useEffect(() => {
    if (firstLoad && account) {
      setFirstLoad(false)
      if (account.preferences) {
        setCurrentPreferences(JSON.parse(JSON.stringify(account.preferences)))
        setStartingPref(JSON.parse(JSON.stringify(account.preferences)))
      } else {
        setCurrentPreferences({
          my_account: true,
          my_assignment: true,
          my_client_info: true,
          my_client_role: true,
          my_client_contact: true,
          my_client_additional_info: true,
          my_client_upc_list: true,
        })
        setStartingPref({
          my_account: true,
          my_assignment: true,
          my_client_info: true,
          my_client_role: true,
          my_client_contact: true,
          my_client_additional_info: true,
          my_client_upc_list: true,
        })
      }
    }
  })
  const [redirectHome, setRedirectHome] = useState(false)

  const prefKeys = [
    'my_account',
    'my_assignment',
    'my_client_info',
    'my_client_role',
    'my_client_contact',
  ]
  const prefTitles = [
    'Changes to My Account',
    'Changes to My Assignments',
    "My Client's General Information Changed",
    'Roles on My Clients Changed',
    "My Client's Contacts Change",
  ]
  /*
  future additions:
    'my_client_additional_info',
    'my_client_upc_list',
  */

  function handleChange(key) {
    var new_obj = JSON.parse(JSON.stringify(currentPreferences))
    new_obj[key] = !new_obj[key]
    setCurrentPreferences(new_obj)
  }
  async function handleSubmit() {
    setRedirectHome(true)
    await setDoc(
      doc(db, 'accounts', account.id),
      { preferences: currentPreferences },
      { merge: true }
    )
      .then(() => {
        alert('Changes Have been saved.')
        window.location.reload()
      })
      .catch(() => {
        alert('Changes could not be saved properly, please try again later.')
      })
  }
  function changeHasBeenMade(startingPref, currentPreferences) {
    console.log(startingPref, currentPreferences)
    var change_has_been_made = false
    Object.keys(startingPref).forEach((key) => {
      if (startingPref[key] !== currentPreferences[key]) {
        change_has_been_made = true
      }
    })
    return change_has_been_made
  }

  return (
    <StandardLayout title="Report Issue" reloadData={props.reloadData}>
      {redirectHome && <Navigate to="/" replace />}
      <Card placeholder="." className="flex h-full w-fit flex-row rounded-lg">
        <div className="flex flex-col h-full w-fit p-4 justify-around">
          <div className="w-fit flex flex-col">
            <div className="flex flex-row align-middle">
              <Typography variant="h4" placeholder={'.'} className="w-full">
                Notification Preferences
              </Typography>
              <div className="pb-1 pl-2">
                <Chip value="BETA" variant="ghost" color="blue" />
              </div>
            </div>
            <hr className=" border-2 border-blue-800"></hr>
          </div>
          {firstLoad ? (
            <Loading />
          ) : (
            <>
              <div className="p-4">
                Using the check boxes below, you can decide which events you
                would like to recieve a notification for.
              </div>
              <div className="p-4 flex flex-col">
                {prefKeys.map((key, index) => {
                  if (startingPref[key]) {
                    return (
                      <div className="flex flex-row w-96 justify-between">
                        <Checkbox
                          onClick={() => {
                            handleChange(key)
                          }}
                          crossOrigin={'.'}
                          defaultChecked
                          label={prefTitles[index]}
                        />
                      </div>
                    )
                  } else {
                    return (
                      <div className="flex flex-row w-96 justify-between">
                        <Checkbox
                          onClick={() => {
                            handleChange(key)
                          }}
                          crossOrigin={'.'}
                          label={prefTitles[index]}
                        />
                      </div>
                    )
                  }
                })}
              </div>
            </>
          )}
          <div className="pt-4 pl-4">
            {changeHasBeenMade(startingPref, currentPreferences) ? (
              <Button placeholder={'.'} onClick={handleSubmit} color="green">
                Submit
              </Button>
            ) : (
              <Button placeholder={'.'} disabled color="green">
                Submit
              </Button>
            )}
          </div>
        </div>
      </Card>
    </StandardLayout>
  )
}
