import Turnstile, { useTurnstile } from 'react-turnstile'

export function TurnstileWidget(props: { setValidResponse: Function }) {
  const turnstile = useTurnstile()
  return (
    <Turnstile
      fixedSize={true}
      theme="light"
      sitekey="0x4AAAAAAAYDKSUpig5Q-9Kq"
      onVerify={(token) => {
        fetch('/login', {
          method: 'POST',
          body: JSON.stringify({ token }),
        }).then((response) => {
          if (!response.ok) turnstile.reset()
          if (response.ok) props.setValidResponse()
        })
      }}
    />
  )
}
