import {
  Card,
  Chip,
  IconButton,
  Typography,
  Avatar,
  Button,
  Tabs,
  TabsHeader,
  TabPanel,
  Tab,
  TabsBody,
} from '@material-tailwind/react'
import { useState, useEffect, useContext } from 'react'
import '../../App.css'
import Loading from '../../components/Loading'
import { PencilIcon, UserIcon } from '@heroicons/react/24/outline'
import { Link} from 'react-router-dom'
import StandardLayout from '../../components/StandardLayout'
import {
  AccountsContext,
  CurrentUserContext,
  PermissionsContext,
} from '../../contexts'
import { AdditionalInfo } from '../../components/AdditionalInfo/AdditionalInfo'
import { client } from '../../types/client'
import UPCList from '../../components/UPCList'
import Payments from '../../components/AdditionalInfo/Payments'
import { db } from '../../firebase'
import { doc, getDoc, onSnapshot } from 'firebase/firestore'
import ensureClientType from '../../functions/ensureType/ensureClientType'

export default function SingleClient(props: { reloadData: Function }) {
  // Initialize Firebase & Firestore
  type account_set = {
    [key: string]: string[]
  }

  const currentUser = useContext(CurrentUserContext)
  const accounts = useContext(AccountsContext)
  const permissions = useContext(PermissionsContext)

  const [firstLoad, setFirstLoad] = useState(true)
  const [isMyClient, setIsMyClient] = useState(false)
  const [title, setTitle] = useState('Single Client')

  const [roles, setRoles] = useState<account_set | undefined>()
  const [contacts, setContacts] = useState<account_set | undefined>()
  const [client, setClient] = useState<client | undefined>()

  const [editMode, setEditmode] = useState(false)
  const [hasContacts, setHasContacts] = useState(false)
  const [hasRoles, setHasRoles] = useState(false)
  const fields = {
    file_id: {
      title: 'ID',
      key: 'file_id',
    },
    aliases: {
      title: 'Aliases',
      key: 'aliases',
    },
    brands: {
      title: 'Brands',
      key: 'brands',
    },
    active: {
      title: 'Active',
      key: 'active',
    },
    potential_client: {
      title: 'Potential Client',
      key: 'potential_client',
    },
    start_date: {
      title: 'Start Date',
      key: 'start_date',
    },
    end_date: {
      title: 'Term Date',
      key: 'end_date',
    },
    commission_file: {
      title: 'File',
      key: 'commission_file',
    },
    tier: {
      title: 'Tier',
      key: 'tier',
    },
  }
  // eslint-disable-next-line
  useEffect(() => {
    if (firstLoad && accounts) {
      getClient()
      setFirstLoad(false)
    }
  })

  // functions
  function handleEdit() {
    setEditmode(!editMode)
  }
  function evaluateContactsAndRoles(roles, contacts) {
    if (roles !== undefined) {
      try {
        if (Object.keys(roles).length > 0) {
          setHasRoles(true)
        }
      } catch (err) {
        console.log(err)
      }
    }
    if (contacts !== undefined) {
      try {
        if (Object.keys(contacts).length > 0) {
          setHasContacts(true)
        }
      } catch (err) {
        console.log(err)
      }
    }
  }
  async function getClient() {
    const id = window.location.href.split('#').pop()
    const docRef = doc(db, 'clients', String(id))
    const unsub = onSnapshot(docRef, (querySnapshot) => {
      if (querySnapshot.exists()) {
        const data = querySnapshot.data()
        setTitle(data.name)
        setRoles(data.roles)
        setContacts(data.contacts)
        evaluateContactsAndRoles(data.roles, data.contacts)
        checkIsMyClient(data.roles)
        setClient(ensureClientType(data))
      } else {
        alert('There was an issue loading the data for this client.')
      }
    })
  }
  function checkIsMyClient(roles) {
    const user_id = currentUser.id
    for (let i in roles) {
      const account_ids = roles[i]
      for (let j in account_ids) {
        if (account_ids[j] === user_id) {
          setIsMyClient(true)
        }
      }
    }
  }
  function sortAlphabetical(array: string[]) {
    return array.sort((a, b) => {
      return a.localeCompare(b)
    })
  }
  // components
  function genInfoLine(field, client) {
    if (client[field.key] !== undefined) {
      if (typeof client[field.key] === 'object') {
        if (client[field.key].length > 0) {
          return (
            <tr key={field.key} className="w-64">
              <td key="field" className="border-b border-blue-gray-50 p-3 px-4">
                <Typography
                  placeholder="."
                  variant="small"
                  color="blue-gray"
                  className="font-bold"
                >
                  {field.title}
                </Typography>
              </td>
              <td key="value" className="border-b border-blue-gray-50 p-3 px-4">
                <Typography
                  placeholder="."
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  {client[field.key].length > 0 ? (
                    <>
                      {sortAlphabetical(client[field.key]).map(
                        (value, index) => {
                          return (
                            <div
                              className={String(value) + '_' + String(index)}
                            >
                              {value + ', '}
                              <br></br>
                            </div>
                          )
                        }
                      )}
                    </>
                  ) : (
                    <>{client[field.key]}</>
                  )}
                </Typography>
              </td>
            </tr>
          )
        } else {
          return <></>
        }
      } else {
        if (!(client[field.key] === '')) {
          return (
            <div>
              <tr key={field.key} className="w-64">
                <td
                  key="field"
                  className="border-b border-blue-gray-50 p-3 px-4"
                >
                  <Typography
                    placeholder="."
                    variant="small"
                    color="blue-gray"
                    className="font-bold"
                  >
                    {field.title}
                  </Typography>
                </td>
                <td
                  key="value"
                  className="border-b border-blue-gray-50 p-2 px-4"
                >
                  <Typography
                    placeholder="."
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {client[field.key]}
                  </Typography>
                </td>
              </tr>
            </div>
          )
        } else {
          return <></>
        }
      }
    } else {
      return <></>
    }
  }
  function chipInfoLine(field, type, client) {
    return (
      <tr key="active">
        <td key="field" className="border-b border-blue-gray-50 p-3 px-4">
          <Typography
            placeholder="."
            variant="small"
            color="blue-gray"
            className="font-bold"
          >
            {field.title}
          </Typography>
        </td>
        <td key="value" className="border-b border-blue-gray-50 p-2 px-4">
          <Typography
            placeholder="."
            variant="small"
            color="blue-gray"
            className="font-normal"
          >
            {type === 'active' ? (
              <>
                {client.active ? (
                  <Chip
                    variant="ghost"
                    color="green"
                    size="sm"
                    value="Active"
                    icon={
                      <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-green-900 content-['']" />
                    }
                  />
                ) : (
                  <Chip
                    variant="ghost"
                    color="red"
                    size="sm"
                    value="In-Active"
                    icon={
                      <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-red-900 content-['']" />
                    }
                  />
                )}
              </>
            ) : (
              <>
                <Chip
                  variant="ghost"
                  color="blue"
                  size="sm"
                  value="True"
                  icon={
                    <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-green-900 content-['']" />
                  }
                />
              </>
            )}
          </Typography>
        </td>
      </tr>
    )
  }
  function RolesDisplay({ roles }) {
    function sortByRoleTitle(a: string, b: string) {
      const titles = [
        'VP',
        'Director',
        'Business Manager',
        'Assistant Business Manager',
        'Insights',
        'Assistant Insights Manager',
        'Orders',
        'Claims',
        'Marketing',
        'Assistant',
      ]
      var a_num
      var b_num
      if (titles.includes(a)) {
        a_num = titles.indexOf(a)
      } else {
        a_num = 20
      }
      if (titles.includes(b)) {
        b_num = titles.indexOf(b)
      } else {
        b_num = 20
      }
      const result = a_num - b_num
      return result
    }

    return (
      <div className="flex h-fit w-full flex-row flex-wrap">
        {Object.keys(roles)
          .sort(sortByRoleTitle)
          .map((key) => {
            return (
              <div key={'roles_display_' + key}>
                {roles[key] && <>{rolesBlock(key, roles[key])}</>}
              </div>
            )
          })}
      </div>
    )
  }
  function rolesBlock(key, ids: string[]) {
    return (
      <div className="h-fit max-w-sm p-4" key={key + '_rolesBlock'}>
        <Typography
          placeholder="."
          variant="h5"
          color="blue-gray"
          className="px-4"
        >
          {key}
        </Typography>
        <hr className=" w-full border-2 border-blue-700" />
        <div className="">
          {ids && (
            <>
              {ids.map((id) => {
                var account_data = {}
                for (let index in accounts) {
                  if (accounts[index].id === String(id)) {
                    account_data = accounts[index]
                  }
                }
                try {
                  return AccountCard(account_data, id, key)
                } catch {
                  console.log('Could Not Render Account #' + id)
                  return <></>
                }
              })}
            </>
          )}
        </div>
      </div>
    )
  }
  function ContactsDisplay({ contacts }) {
    function sortByRoleTitle(a: string, b: string) {
      const titles = [
        'Primary Contact',
        'Marketing or Creative Contact',
        'Billing Contact',
        'Sales Contact',
        'Operations Contact',
        'Invoice Contact',
      ]
      var a_num
      var b_num
      if (titles.includes(a)) {
        a_num = titles.indexOf(a)
      } else {
        a_num = 20
      }
      if (titles.includes(b)) {
        b_num = titles.indexOf(b)
      } else {
        b_num = 20
      }
      const result = a_num - b_num
      return result
    }

    return (
      <div className="flex h-fit w-full flex-row flex-wrap">
        {Object.keys(contacts)
          .sort(sortByRoleTitle)
          .map((key) => {
            return (
              <div key={'contacts_display_' + key}>
                {contacts[key] && <>{contactsBlock(key, contacts[key])} </>}
              </div>
            )
          })}
      </div>
    )
  }
  function contactsBlock(key, ids: string[]) {
    return (
      <div className="h-fit max-w-sm p-4" key={key + '_contactsBlock'}>
        <Typography
          placeholder="."
          variant="h5"
          color="blue-gray"
          className="px-4"
        >
          {key}
        </Typography>
        <hr className=" w-full border-2 border-blue-700" />
        <div className="">
          {ids.map((id) => {
            var account_data = {}
            for (let index in accounts) {
              if (accounts[index].id === String(id)) {
                account_data = accounts[index]
              }
            }
            try {
              return AccountCard(account_data, id, key)
            } catch {
              console.log('Could Not Render Account #' + id)
              return <></>
            }
          })}
        </div>
      </div>
    )
  }
  function AccountCard(account, acct_id, role_title) {
    return (
      <div className="p-2" key={acct_id + '_acct_card_' + role_title}>
        <Link to={'/view/account#' + acct_id}>
          <Card
            placeholder="."
            className=" h-30 flex w-80 flex-row overflow-hidden rounded-lg border border-gray-300 shadow-md"
          >
            {account.internal ||
            (account.avatar !== '' && account.avatar !== undefined) ? (
              <div className="w-1/3">
                <Avatar
                  placeholder="."
                  src={account?.avatar}
                  className="h-20 w-20 p-2"
                />
              </div>
            ) : (
              <div className="w-1/3">
                <UserIcon className="h-20 w-20 p-2" />
              </div>
            )}
            <div className="flex w-2/3 flex-col pl-2 pt-3">
              <Typography placeholder="." variant="h6" color="blue-gray">
                {account?.name}
              </Typography>
              {account.phone !== undefined && account.phone !== '' ? (
                <Typography placeholder="." variant="small" color="blue-gray">
                  {account?.phone}
                </Typography>
              ) : (
                <>
                  {account['Cell Phone'] !== undefined &&
                    account['Cell Phone'] !== '' && (
                      <Typography
                        placeholder="."
                        variant="small"
                        color="blue-gray"
                      >
                        {account['Cell Phone']}
                      </Typography>
                    )}
                </>
              )}
              <Typography placeholder="." variant="small" color="blue-gray">
                {account?.email}
              </Typography>
            </div>
          </Card>
        </Link>
      </div>
    )
  }

  const viewPayments =
    permissions.view_payments ||
    (permissions.view_my_client_payments && isMyClient)

  return (
    <StandardLayout title={title} reloadData={props.reloadData}>
      <Card placeholder="." className="flex h-fit w-full flex-col rounded-lg">
        {client ? (
          <div className="h-fit w-full">
            <div className="h-fit w-full">
              <div className="flex h-fit w-full flex-row flex-wrap">
                <div className="flex h-fit w-full flex-col flex-wrap  overflow-auto">
                  <div className="flex h-fit w-full flex-col justify-center">
                    <div key="table_and_headers" className="h-fit w-full p-4 ">
                      <div className="flex h-full flex-row justify-center flex-wrap">
                        {client.logo !== '' ? (
                          <div className="flex flex-col">
                            <img
                              className="h-40 min-w-20 object-scale-down"
                              src={client.logo}
                              alt={client.name}
                            />
                          </div>
                        ) : (
                          <>
                            <Typography
                              placeholder="."
                              variant="h4"
                              className="pb-2"
                            >
                              {client.name}
                            </Typography>
                          </>
                        )}
                        <div className="flex flex-col justify-center px-6">
                          <div className="w-full min-w-max table-auto text-left">
                            <div className=" flex max-h-32 flex-row flex-wrap">
                              <div className="flex flex-col">
                                {genInfoLine(fields.file_id, client)}
                                {permissions.view_tiers && (
                                  <>{genInfoLine(fields.tier, client)}</>
                                )}
                                {genInfoLine(fields.commission_file, client)}
                              </div>
                              <div className="flex flex-col">
                                {client.potential_client &&
                                  chipInfoLine(
                                    fields.potential_client,
                                    'potential',
                                    client
                                  )}
                                {chipInfoLine(fields.active, 'active', client)}
                                {genInfoLine(fields.start_date, client)}
                                {genInfoLine(fields.end_date, client)}
                              </div>
                              <div className="flex flex-col max-h-40 overflow-auto">
                                {genInfoLine(fields.aliases, client)}
                              </div>
                              <div className="flex flex-col max-h-40 overflow-auto">
                                {genInfoLine(fields.brands, client)}
                              </div>
                            </div>
                          </div>
                        </div>
                        {editMode &&
                          (permissions.edit_all_clients ||
                            (permissions.edit_assigned_clients &&
                              isMyClient)) && (
                            <div className=" flex flex-row-reverse">
                              <Link to={'/edit/client#' + String(client.id)}>
                                <IconButton placeholder="." variant="text">
                                  <PencilIcon className="h-6 w-6"></PencilIcon>
                                </IconButton>
                              </Link>
                            </div>
                          )}
                      </div>
                    </div>
                    <Tabs value="General">
                      <TabsHeader
                        placeholder={'.'}
                        className="flex justify-around"
                      >
                        <Tab
                          placeholder={'.'}
                          key={'General'}
                          value="General"
                          className=" w-32"
                        >
                          General
                        </Tab>
                        {viewPayments && (
                          <Tab
                            placeholder={'.'}
                            key={'Payments'}
                            value="Payments"
                            className=" w-32"
                          >
                            Payments
                          </Tab>
                        )}
                        <Tab
                          placeholder={'.'}
                          key={'UPCs'}
                          value="UPCs"
                          className=" w-32"
                        >
                          UPCs
                        </Tab>
                      </TabsHeader>
                      <TabsBody placeholder={'.'}>
                        <TabPanel key={'General'} value="General">
                          {client.id !== undefined ? (
                            <AdditionalInfo client_id={client.id} />
                          ) : (
                            <div className="p-20">
                              <Loading />
                            </div>
                          )}
                          <hr className=" border-gray-400 py-4"></hr>
                          {contacts && roles && (
                            <div className="flex w-full flex-row">
                              {hasRoles && (
                                <div className="h-fit w-1/2 p-2">
                                  <div className="ma-w-fit flex w-40 flex-row p-2">
                                    <Typography placeholder="." variant="h4">
                                      Roles
                                    </Typography>
                                    {editMode &&
                                      (permissions.edit_all_clients === true ||
                                        (permissions.edit_my_client_roles &&
                                          isMyClient) ||
                                        permissions.edit_all_roles) && (
                                        <div className="flex flex-row-reverse p-0 pl-2">
                                          <Link
                                            to={
                                              '/edit/client/roles#' +
                                              String(client.id)
                                            }
                                          >
                                            <IconButton
                                              placeholder="."
                                              variant="text"
                                            >
                                              <PencilIcon className="h-6 w-6"></PencilIcon>
                                            </IconButton>
                                          </Link>
                                        </div>
                                      )}
                                  </div>
                                  <div className="flex flex-row flex-wrap">
                                    <RolesDisplay roles={roles} />
                                  </div>
                                </div>
                              )}
                              {hasContacts && (
                                <div className="h-fit w-1/2 p-2">
                                  <div className="ma-w-fit flex w-40 flex-row p-2">
                                    <Typography placeholder="." variant="h4">
                                      Contacts
                                    </Typography>
                                    {editMode &&
                                      (permissions.edit_all_clients === true ||
                                        (permissions.edit_my_client_contacts &&
                                          isMyClient) ||
                                        permissions.edit_all_contacts) && (
                                        <div className="flex flex-row-reverse p-0 pl-2">
                                          <Link
                                            to={
                                              '/edit/client/contacts#' +
                                              String(client.id)
                                            }
                                          >
                                            <IconButton
                                              placeholder="."
                                              variant="text"
                                            >
                                              <PencilIcon className="h-6 w-6"></PencilIcon>
                                            </IconButton>
                                          </Link>
                                        </div>
                                      )}
                                  </div>
                                  <div className="flex flex-row flex-wrap">
                                    <ContactsDisplay contacts={contacts} />
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                          <div className="flex w-fit flex-col p-4">
                            <div className="flex flex-row p-4 ">
                              {!hasRoles && editMode && (
                                <div className="p-2">
                                  <Link to={'/edit/client/roles#' + client.id}>
                                    <Button placeholder=".">Add Roles</Button>
                                  </Link>
                                </div>
                              )}
                              {!hasContacts && editMode && (
                                <div className="p-2">
                                  <Link
                                    to={'/edit/client/contacts#' + client.id}
                                  >
                                    <Button placeholder=".">
                                      Add Contacts
                                    </Button>
                                  </Link>
                                </div>
                              )}
                            </div>
                            {(permissions.edit_all_clients === true ||
                              (permissions.edit_assigned_clients &&
                                isMyClient) ||
                              permissions.edit_all_roles ||
                              permissions.edit_all_contacts ||
                              (permissions.edit_my_client_contacts &&
                                isMyClient) ||
                              (permissions.edit_my_client_roles &&
                                isMyClient)) && (
                              <Button
                                placeholder="."
                                onClick={handleEdit}
                                variant="text"
                                className="text-gray-500"
                              >
                                {editMode ? (
                                  <>Return to regular view</>
                                ) : (
                                  <>Edit {client?.name}</>
                                )}
                              </Button>
                            )}
                          </div>
                        </TabPanel>
                        <TabPanel key={'Payments'} value={'Payments'}>
                          {viewPayments && <Payments client_id={client.id} />}
                        </TabPanel>
                        <TabPanel key={'UPCs'} value={'UPCs'}>
                          {client ? (
                            <div className="flex w-full flex-col p-4">
                              <UPCList client={client} />
                            </div>
                          ) : (
                            <div className="p-10">
                              <Loading />
                            </div>
                          )}
                        </TabPanel>
                      </TabsBody>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-96 w-96 p-10">
            <Loading />
          </div>
        )}
      </Card>
    </StandardLayout>
  )
}
