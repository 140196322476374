import { Button } from '@material-tailwind/react'
import { useContext, useState } from 'react'
import { additionalInfo } from '../../types/additionalInfo'
import { doc, setDoc } from 'firebase/firestore'
import { db } from '../../firebase'
import { PermissionsContext } from '../../contexts'

export default function MarketingFields(props: {
  allInfo: additionalInfo
  client_id: string
  reloadParent: Function
}) {
  const client_id = props.client_id
  const permissions = useContext(PermissionsContext)
  const [allInfo, setAllInfo] = useState(props.allInfo)
  const [editMarketing, setEditMarketing] = useState(false)
  async function saveMarketing() {
    if (allInfo !== undefined && allInfo.marketing !== undefined) {
      await setDoc(
        doc(db, 'additional_client_info', client_id),
        { marketing: allInfo.marketing },
        { merge: true }
      )
    }
  }
  function handleChangeMarketingActivePrograms(e) {
    setAllInfo({
      ...allInfo,
      marketing: {
        kpm: allInfo.marketing.kpm,
        active_programs: e.target.value,
      },
    })
  }
  function handleChangeMarketingKpm(e) {
    setAllInfo({
      ...allInfo,
      marketing: {
        kpm: e.target.value,
        active_programs: allInfo.marketing.active_programs,
      },
    })
  }

  return (
    <div className="h-full w-1/2 overflow-auto p-4">
      <div className="flex flex-row px-4">
        <div className="pr-6 text-blue-800">
          <strong>Marketing Fields</strong> <br />
        </div>
        {editMarketing ? (
          <div className="flex w-96 justify-between">
            <Button
              placeholder="."
              variant="text"
              className="p-1 text-gray-500"
              onClick={() => {
                setEditMarketing(false)
                saveMarketing()
              }}
            >
              Save Changes
            </Button>
            <Button
              placeholder="."
              variant="text"
              className="p-1 text-gray-500"
              onClick={() => {
                setEditMarketing(false)
                props.reloadParent()
              }}
            >
              Exit without Saving
            </Button>
          </div>
        ) : (
          <div>
            {permissions.edit_marketing && (
              <Button
                placeholder="."
                variant="text"
                className="p-1 text-gray-500"
                onClick={() => {
                  setEditMarketing(true)
                }}
              >
                Edit
              </Button>
            )}
          </div>
        )}
      </div>
      <div className="h-40 max-h-fit w-full min-w-fit overflow-auto rounded-lg border border-gray-300 p-2 shadow-md">
        <div className="font-bold">KPM</div>
        {editMarketing ? (
          <div>
            <textarea
              className="justify-top flex h-full w-full whitespace-pre-wrap"
              value={allInfo.marketing?.kpm}
              placeholder="Type here..."
              onChange={handleChangeMarketingKpm}
            ></textarea>
          </div>
        ) : (
          <>
            {allInfo !== undefined && allInfo.marketing !== undefined ? (
              <>
                {allInfo.marketing.kpm !== '' ? (
                  <div className="whitespace-pre-wrap">
                    {allInfo.marketing.kpm}
                  </div>
                ) : (
                  <div className="text-gray-700">No Comments at this time.</div>
                )}
              </>
            ) : (
              <div className="text-gray-500">No Comments at this time.</div>
            )}
          </>
        )}
        <div className="font-bold">Active Programs</div>
        {editMarketing ? (
          <div>
            <textarea
              className="justify-top flex h-full w-full whitespace-pre-wrap"
              value={allInfo.marketing?.active_programs}
              placeholder="Type here..."
              onChange={handleChangeMarketingActivePrograms}
            ></textarea>
          </div>
        ) : (
          <>
            {allInfo !== undefined && allInfo.marketing !== undefined ? (
              <>
                {allInfo.marketing.active_programs !== '' ? (
                  <div className="whitespace-pre-wrap">
                    {allInfo.marketing.active_programs}
                  </div>
                ) : (
                  <div className="text-gray-700">No Comments at this time.</div>
                )}
              </>
            ) : (
              <div className="text-gray-500">No Comments at this time.</div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
