import { account } from "../types/account";

export default function findNameFromAccountID(account_id: string, accounts: account[]) {
  var account_name = "Unknown"

  accounts.forEach((account)=>{
    if (account.id === account_id) {
      account_name = account.name
    }
  })
  return account_name
}