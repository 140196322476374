import { useContext, useEffect, useState } from 'react'
import { db, storage } from '../firebase'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import StandardLayout from './StandardLayout'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
  Card,
  Checkbox,
  Chip,
  Dialog,
  DialogBody,
  DialogHeader,
  Drawer,
  IconButton,
  Input,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from '@material-tailwind/react'
import { ArrowDownTrayIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import XLSX from 'xlsx'
import {
  ClientsContext,
  CurrentUserContext,
  PermissionsContext,
} from '../contexts'
import Loading from './Loading'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import readableTimestamp from '../functions/readableTimestamp'

export default function Kompass(props: { reloadData: Function; userComms }) {
  const clients = useContext(ClientsContext)
  const permissions = useContext(PermissionsContext)
  const currentUser = useContext(CurrentUserContext)
  const myComms = props.userComms
  const [kompassLines, setKompassLines] = useState<kompassLine[] | undefined>()
  const [downloadURLs, setDownloadURLs] = useState<
    { title: string; url: string }[]
  >([])
  const [filterByMyClients, setFilterByMyClients] = useState(false)
  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [fieldsOpen, setFieldsOpen] = useState<boolean>(false)
  const [sortOpen, setSortOpen] = useState<boolean>(false)
  const [parsingUpload, setParsingUpload] = useState<boolean>(false)
  const [successfulLines, setSuccessfulLines] = useState<kompassLine[]>([]) // lines parsed correctly by the system.
  const [fileForUpload, setFileForUpload] = useState<File | undefined>()
  const [readyToUpload, setReadyToUpload] = useState<boolean>(false)
  const [failedLines, setFailedLines] = useState<string[][]>([]) // lines that failed for some reason.
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [sortCol, setSortCol] = useState('period') // pulls from table_values
  const [sortDirection, setSortDirection] = useState(true) // descending or ascending vis true and false respectively.
  const [filterCol, setFilterCol] = useState('')
  const [filterFocus, setFilterFocus] = useState('')
  const [filterColTitle, setFilterColTitle] = useState('')
  const [filterDrawer, setFilterDrawer] = useState(false)
  const [fileUploadYear, setFileUploadYear] = useState('')
  const [latestRefresh, setLatestRefresh] = useState('')
  const [kompassDownloads, setKompassDownloads] = useState<
    { title: string; url: string }[]
  >([])
  const [fields, setFields] = useState({
    dept: true,
    type: true,
    commodity: true,
    category: true,
    core_size: true,
    category_manager: false,
    process_manager: false,
    year: true,
    week: true,
    period: true,
    hours: true,
    comments: false,
  })
  const onFileChange = async (event) => {
    try {
      setParsingUpload(true)
      const processing_resutls = await processFile(event.target.files[0])
      if (processing_resutls) {
        setReadyToUpload(true)
        setFileForUpload(event.target.files[0])
        setFileUploadYear(krogerYear)
      }
      setParsingUpload(false)
    } catch (err) {
      setReadyToUpload(false)
      console.log('Error on upload:', err)
    }
  }
  async function handleUpload() {
    try {
      const file_name = 'Orbit Kompass Download ' + krogerYear
      const fileRef = ref(storage, file_name + '.xlsx')
      var download_url = ''
      if (fileForUpload !== undefined) {
        await uploadBytes(fileRef, fileForUpload)
          .then((snapshot) => {
            console.log('File Uploaded.')
            console.log(snapshot.ref)
          })
          .then(async () => {
            await getDownloadURL(ref(storage, file_name + '.xlsx')).then(
              (url) => {
                download_url = url
              }
            )
          })
      } else {
        console.log('Document failed to upload.')
      }

      if (erasePreviousLines) {
        await setDoc(doc(db, 'kroger_info', 'kompass_calendar'), {
          lines: successfulLines,
          downloads: [
            {
              title: krogerYear,
              url: download_url,
            },
          ],
        }).then(() => {
          props.reloadData()
          alert('Data was successfully Uploaded!')
        })
      } else {
        var previous_lines = JSON.parse(JSON.stringify(kompassLines))
        var combined_lines = previous_lines.concat(successfulLines)

        var previous_download_links = JSON.parse(JSON.stringify(downloadURLs))
        var combined_download_links = previous_download_links.concat([
          { title: krogerYear, url: download_url },
        ])
        await setDoc(doc(db, 'kroger_info', 'kompass_calendar'), {
          latest_update: readableTimestamp(),
          lines: combined_lines,
          downloads: combined_download_links,
        }).then(() => {
          alert('Data was successfully Uploaded!')
          props.reloadData()
        })
      }
    } catch (err) {
      alert('An error occured when uploading documents.')
      console.log(err)
    }
    setTimeout(() => {
      console.log('timer hit.')
    }, 2000)
  }
  async function processFile(file_to_parse: File) {
    var successful_lines: kompassLine[] = []
    var failed_lines: string[][] = []

    const file = await file_to_parse.arrayBuffer()
    const workbook = await XLSX.read(file)
    const first_sheet = workbook.Sheets['Data']
    const results = XLSX.utils.sheet_to_json(first_sheet, { header: 1 })

    const rows = results
    console.log('Rows being processed: ', rows.length)
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i]
      if (i > 17) {
        var newKompassLine: kompassLine = {
          dept: '',
          type: '',
          commodity: '',
          category: '',
          core_size: '',
          category_manager: '',
          process_manager: '',
          year: '',
          period: '',
          week: '',
          hours: '',
          comments: '',
        }
        try {
          if (Array.isArray(row)) {
            try {
              if (row[0]) {
                newKompassLine.dept = row[0]
              }
              if (row[1]) {
                newKompassLine.commodity = row[1]
              }
              if (row[2]) {
                newKompassLine.category = row[2]
              }
              if (row[3]) {
                newKompassLine.core_size = row[3]
              }
              if (row[4]) {
                newKompassLine.process_manager = row[4]
              }
              if (row[5]) {
                newKompassLine.category_manager = row[5]
              }
              if (row[6]) {
                newKompassLine.period = row[6]
              }
              if (row[7]) {
                newKompassLine.week = row[7]
              }
              if (row[8]) {
                newKompassLine.hours = row[8]
              }
              if (row[9]) {
                newKompassLine.type = row[9]
              }
              if (row[10]) {
                newKompassLine.comments = row[10]
              }
              newKompassLine.year = krogerYear
              successful_lines.push(newKompassLine)
            } catch (err) {
              console.log('line failed:', err)
              failed_lines.push(row)
            }
          } else {
            console.log('row was not array: ', row)
          }
        } catch (err) {
          console.log('An Error Occured when parsing your csv.', row, err)
          if (Array.isArray(row)) {
            failedLines.push(row)
          } else {
            alert(
              'One for the rows is not an array and is being skipped. Check the console for more information.'
            )
            console.log('Non-array row: ', row)
          }
        }
      }
    }
    if (failed_lines !== undefined) {
      console.log('rejected_lines count: ', failed_lines.length)
      setFailedLines(failed_lines)
    }
    if (successful_lines !== undefined) {
      setSuccessfulLines(successful_lines)
      console.log('Successfully parsed lines:', successful_lines.length)
      console.log(successful_lines)
    }
    if (successful_lines !== undefined) {
      return true
    } else {
      return false
    }
  }
  async function getKompassInfo() {
    await getDoc(doc(db, 'kroger_info', 'kompass_calendar'))
      .then((snapShot) => {
        if (snapShot.exists()) {
          const data = snapShot.data()
          if (data.lines !== undefined) {
            setKompassLines(data.lines)
          } else {
            console.log('No kompass lines were found.')
            setKompassLines([])
          }
          if (data.downloads) {
            setKompassDownloads(data.downloads)
            setDownloadURLs(data.downloads)
          } else {
            console.log('No kompass downloads were found.')
            setKompassDownloads([])
          }
          if (data.latest_update) {
            setLatestRefresh(data.latest_update)
          }
        }
      })
      .then(() => {
        setLoading(true)
      })
  }
  function handleDialog() {
    setDialogOpen(!dialogOpen)
    setErasePreviousLines(true)
  }
  function toggleField(field) {
    console.log(fields)
    setFields({ ...fields, [field]: !fields[field] })
  }
  const [sortTitle, setSortTitle] = useState('')
  const [sortingByDate, setSortingByDate] = useState(false)
  function setSort(col_value: string, date = false) {
    if (col_value !== sortCol) {
      setSortCol(col_value)
      setSortDirection(true)
      setSortingByDate(date)
    } else {
      setSortDirection(!sortDirection)
    }
    console.log(col_value)
  }
  function filterFocusOptions(rows: kompassLine[]) {
    var temp_array: string[] = []
    rows.forEach((row: kompassLine) => {
      if (row[filterCol]) {
        if (!temp_array.includes(String(row[filterCol]))) {
          temp_array.push(String(row[filterCol]))
        }
      }
    })
    return temp_array
  }
  const [loading, setLoading] = useState(true)

  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    if (firstLoad && clients && currentUser) {
      getKompassInfo()
      setFirstLoad(false)
    }
  }, [myComms, firstLoad])

  function isMyComm(row: kompassLine) {
    try {
      if (myComms) return myComms.includes(Number(row.commodity))
      else return true
    } catch (err) {
      console.log('isMyComm Failed:', row, err)
      return true
    }
  }
  function handleChangeMyComms() {
    setFilterByMyClients(!filterByMyClients)
  }
  function filterByMyComms(rows: kompassLine[]) {
    if (filterByMyClients) {
      return rows.filter((row) => {
        if (isMyComm(row)) {
          return row
        }
      })
    } else {
      return rows
    }
  }
  function filterRows(rows: kompassLine[]) {
    return rows.filter((row) => {
      if (row['category']) {
        if (filterCol !== '' && filterFocus !== '') {
          if (row[filterCol] == filterFocus) {
            return row
          }
        } else {
          return row
        }
      }
    })
  }
  function numberToStringWithLeadingZeros(number, totalCharacters) {
    // Convert the number to a string
    var numberString = String(number)

    // Calculate the number of leading zeros required
    var leadingZerosCount = Math.max(totalCharacters - numberString.length, 0)

    // Generate the leading zeros string
    var leadingZeros = '0'.repeat(leadingZerosCount)

    // Concatenate the leading zeros with the number
    return leadingZeros + numberString
  }
  function sortRows(rows: kompassLine[]) {
    if (sortingByDate) {
      if (sortDirection) {
        // descending
        return rows.slice().sort((a, b) => {
          var a_comp_value = ''
          a_comp_value += numberToStringWithLeadingZeros(a.year, 4)
          a_comp_value += numberToStringWithLeadingZeros(a.period, 2)
          a_comp_value += numberToStringWithLeadingZeros(a.week, 2)
          var b_comp_value = ''
          b_comp_value += numberToStringWithLeadingZeros(b.year, 4)
          b_comp_value += numberToStringWithLeadingZeros(b.period, 2)
          b_comp_value += numberToStringWithLeadingZeros(b.week, 2)
          console.log(a_comp_value, b_comp_value)
          console.log('a', a.year, a.period, a.week)
          return Number(a_comp_value) - Number(b_comp_value)
        })
      } else {
        // descending
        return rows.slice().sort((a, b) => {
          var a_comp_value = ''
          a_comp_value += numberToStringWithLeadingZeros(a.year, 4)
          a_comp_value += numberToStringWithLeadingZeros(a.period, 2)
          a_comp_value += numberToStringWithLeadingZeros(a.week, 2)
          var b_comp_value = ''
          b_comp_value += numberToStringWithLeadingZeros(b.year, 4)
          b_comp_value += numberToStringWithLeadingZeros(b.period, 2)
          b_comp_value += numberToStringWithLeadingZeros(b.week, 2)
          console.log(a_comp_value, b_comp_value)
          return Number(a_comp_value) - Number(b_comp_value)
        })
      }
    } else {
      if (sortDirection) {
        if (table_type_refs[sortCol] === 'string') {
          return rows
            .slice()
            .sort((a, b) => a[sortCol].localeCompare(b[sortCol]))
        } else {
          return rows
            .slice()
            .sort((a, b) => Number(b[sortCol]) - Number(a[sortCol]))
        }
      } else {
        if (table_type_refs[sortCol] === 'string') {
          return rows
            .slice()
            .sort((b, a) => a[sortCol].localeCompare(b[sortCol]))
        } else {
          return rows
            .slice()
            .sort((b, a) => Number(b[sortCol]) - Number(a[sortCol])) // input is swapped for reverse sorting
        }
      }
    }
  }
  function sortGeneral(values: string[], col) {
    if (table_type_refs[col] === 'string') {
      return values.slice().sort((a, b) => a.localeCompare(b))
    } else {
      return values.slice().sort((a, b) => Number(a) - Number(b))
    }
  }
  const [searchQuery, setSearchQuery] = useState('')
  function filterBySearch(values: string[], query) {
    return values.filter((value) => {
      if (value.includes(query)) {
        return true
      } else {
        return false
      }
    })
  }
  const [krogerYear, setKrogerYear] = useState('')
  function handleKrogerYear(e) {
    setKrogerYear(e.target.value)
  }
  const [erasePreviousLines, setErasePreviousLines] = useState(true)
  function toggleErasePreviousLines() {
    setErasePreviousLines(!erasePreviousLines)
  }

  const TABLE_HEAD = [
    'Dept.',
    'Type',
    'Comm. Number',
    'Category',
    'Core Size',
    'Category Manager',
    'Process Manager',
    'Year',
    'Period',
    'Week',
    'Hours',
    'Comments',
  ]
  const sorting_headers = [
    'Dept.',
    'Type',
    'Comm. Number',
    'Category',
    'Core Size',
    'Category Manager',
    'Process Manager',
    'Date',
    'Hours',
    'Comments',
  ]
  const table_values = [
    'dept',
    'type',
    'commodity',
    'category',
    'core_size',
    'category_manager',
    'process_manager',
    'year',
    'period',
    'week',
    'hours',
    'comments',
  ]
  const table_type_refs = {
    dept: 'int',
    type: 'string',
    commodity: 'int',
    category: 'string',
    core_size: 'int',
    category_manager: 'string',
    process_manager: 'string',
    year: 'int',
    week: 'int',
    period: 'int',
    hours: 'int',
    comments: 'string',
  }
  const [wholeCardKey, setWholeCardKey] = useState('940')
  function reloadWholeCard() {
    setWholeCardKey(String(Number(wholeCardKey) + 2))
  }

  return (
    // needs reworking to match new type
    <StandardLayout title="Kompass" reloadData={props.reloadData}>
      <Card
        placeholder="."
        className="flex h-full w-full flex-row rounded-lg"
        key={wholeCardKey}
      >
        <div className="p-4 min-w-3/4 w-fit">
          <div className="p-4 flex justify-between">
            <div className="flex flex-col">
              <Typography
                placeholder={'.'}
                variant="h3"
                className=" text-black"
              >
                Kompass Updates
              </Typography>
              <div className="pt-1">
                <Typography placeholder={'.'} variant="small">
                  Latest Refresh: {latestRefresh}
                </Typography>
              </div>
            </div>
            <div className="px-4 flex flex-row justify-between">
              {(filterFocus !== '' || sortTitle !== '') && (
                <div className="px-2">
                  <Button
                    placeholder={'.'}
                    onClick={() => {
                      setFilterFocus('')
                      setFilterCol('')
                      setSortCol('period') // pulls from table_values
                      setSortDirection(true) // descending or ascending vis true and false respectively.
                      setFilterColTitle('')
                      reloadWholeCard()
                      setSortTitle('')
                    }}
                    className="p-3"
                    variant="text"
                  >
                    Clear Options
                  </Button>
                </div>
              )}
              {permissions.edit_presets && (
                <div onClick={handleDialog}>
                  <IconButton placeholder={'.'} variant="outlined">
                    <ArrowUpTrayIcon className="w-6 h-6" />
                  </IconButton>
                </div>
              )}
              <div className="px-2">
                <Menu>
                  <MenuHandler>
                    <IconButton placeholder={'.'} variant="outlined">
                      <ArrowDownTrayIcon className="w-6 h-6" />
                    </IconButton>
                  </MenuHandler>
                  <MenuList placeholder={'.'}>
                    {downloadURLs.map((download_obj) => {
                      return (
                        <a href={download_obj.url}>
                          <MenuItem placeholder={'.'}>
                            <>{download_obj.title}</>
                          </MenuItem>
                        </a>
                      )
                    })}
                  </MenuList>
                </Menu>
              </div>
            </div>
          </div>
          <div className="p-4 w-fit">
            <Card className="p-0 rounded-md w-fit shadow-sm overflow-clip border" placeholder={'.'}>
            <div className='overflow-auto' style={{height: '75vh'}}><table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head, index) => {
                      return (
                        <>
                          {fields[table_values[index]] && (
                            <th
                              key={head}
                              className="border-b border-blue-gray-100 bg-blue-gray-50 p-2"
                            >
                              <Typography
                                placeholder={'.'}
                                variant="small"
                                color="blue-gray"
                                className="font-normal leading-none opacity-70"
                              >
                                {head}
                              </Typography>
                            </th>
                          )}
                        </>
                      )
                    })}
                  </tr>
                </thead>
                {kompassLines !== undefined && kompassLines.length > 0 ? (
                  <tbody>
                    {sortRows(filterRows(filterByMyComms(kompassLines))).map(
                      (line, index) => {
                        const isLast = index === kompassLines.length - 1
                        const classes = isLast
                          ? 'p-2'
                          : 'p-2 border-b border-blue-gray-50'
                        const name = 'kompass_line_' + String(index + 1)
                        return (
                          <tr key={name}>
                            {table_values.map((value) => {
                              return (
                                <>
                                  {fields[value] && (
                                    <td className={classes}>
                                      <Typography
                                        placeholder={'.'}
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                      >
                                        {line[value]}
                                      </Typography>
                                    </td>
                                  )}
                                </>
                              )
                            })}
                          </tr>
                        )
                      }
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    {loading ? (
                      <div className="p-10 justify-center w-full">
                        <Loading />
                      </div>
                    ) : (
                      <div className="p-4">No Lines Found</div>
                    )}
                  </tbody>
                )}
              </table>
              </div>
            </Card>
          </div>
        </div>
        <div className=" flex flex-col">
          <div className=" w-64 pt-4">
            <Accordion placeholder="." open={fieldsOpen}>
              <AccordionHeader
                placeholder="."
                onClick={() => setFieldsOpen(!fieldsOpen)}
              >
                Fields
              </AccordionHeader>
              <AccordionBody className="w-full">
                <div className="flex max-h-64 w-full  flex-col border-2 rounded-lg overflow-auto p-2">
                  {table_values.map((value, index) => {
                    if (
                      value === 'comments' ||
                      value === 'process_manager' ||
                      value === 'category_manager'
                    ) {
                      return (
                        <Checkbox
                          crossOrigin={'false'}
                          onClick={() => {
                            toggleField(value)
                          }}
                          label={TABLE_HEAD[index]}
                        />
                      )
                    } else {
                      return (
                        <Checkbox
                          crossOrigin={'false'}
                          onClick={() => {
                            toggleField(value)
                          }}
                          defaultChecked
                          label={TABLE_HEAD[index]}
                        />
                      )
                    }
                  })}
                </div>
              </AccordionBody>
            </Accordion>
          </div>
          <div className=" w-64 pt-4">
            <Accordion placeholder="." open={sortOpen}>
              <AccordionHeader
                placeholder="."
                onClick={() => setSortOpen(!sortOpen)}
              >
                Sort {sortTitle !== '' ? '( ' + sortTitle + ' )' : ''}
              </AccordionHeader>
              <AccordionBody className="w-full">
                <div className="flex max-h-64 w-full flex-col border-2 rounded-lg overflow-auto p-2">
                  {sorting_headers.map((title, index) => {
                    var date_sorting = false
                    if (title === 'Date') {
                      date_sorting = true
                    }
                    return (
                      <div className="p-1">
                        <div>
                          {sortCol === table_values[index] ? (
                            <Button
                              placeholder={'.'}
                              p-1
                              variant="outlined"
                              onClick={() => {
                                setSort(table_values[index], date_sorting)
                                setSortTitle(title)
                              }}
                            >
                              {title}
                            </Button>
                          ) : (
                            <Button
                              placeholder={'.'}
                              p-1
                              variant="text"
                              onClick={() => {
                                setSort(table_values[index], date_sorting)
                                setSortTitle(title)
                              }}
                            >
                              {title}
                            </Button>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </AccordionBody>
            </Accordion>
          </div>
          <div className=" w-64 pt-4">
            <Accordion placeholder="." open={filterOpen}>
              <AccordionHeader
                placeholder="."
                onClick={() => setFilterOpen(!filterOpen)}
              >
                Filter{' '}
                {filterColTitle !== '' ? '( ' + filterColTitle + ')' : ''}
              </AccordionHeader>
              <AccordionBody className="w-full">
                <div className="flex flex-row w-full h-fit justify-around">
                  <Checkbox
                    onChange={handleChangeMyComms}
                    crossOrigin={'false'}
                    label="Only my Commodities"
                  />
                  <div className="pb-1 pt-2 pl-2">
                    <Chip value="BETA" variant="ghost" color="blue" />
                  </div>
                </div>
                <div className="flex max-h-64 w-full flex-col border-2 rounded-lg overflow-auto p-2">
                  {TABLE_HEAD.map((title, index) => {
                    if (title !== 'Comments') {
                      return (
                        <div className="p-1">
                          {filterColTitle === title ? (
                            <Button
                              placeholder={'.'}
                              p-1
                              variant="outlined"
                              onClick={() => {
                                setFilterDrawer(true)
                                setFilterCol(table_values[index])
                                setFilterColTitle(title)
                              }}
                            >
                              {title}
                            </Button>
                          ) : (
                            <Button
                              placeholder={'.'}
                              p-1
                              variant="text"
                              onClick={() => {
                                setFilterDrawer(true)
                                setFilterCol(table_values[index])
                                setFilterColTitle(title)
                              }}
                            >
                              {title}
                            </Button>
                          )}
                        </div>
                      )
                    }
                  })}
                </div>
              </AccordionBody>
            </Accordion>
          </div>
        </div>
      </Card>
      <Drawer
        placeholder={'.'}
        open={filterDrawer}
        onClose={() => setFilterDrawer(false)}
        placement="right"
        size={600}
      >
        <div className="p-10 flex flex-col h-full w-full justify-center">
          <div className="pb-4">
            <Typography placeholder={'.'} variant="h3">
              Filter By
            </Typography>
          </div>
          <hr></hr>
          <div className="pb-4 pt-2">
            <Typography placeholder={'.'} variant="h5">
              Selected Col: {filterColTitle}
            </Typography>
          </div>
          <div className="pb-64">
            <Typography placeholder={'.'} variant="h6">
              Choose Value:
            </Typography>
            <div className="p-4">
              <Input
                label="Search Filter Terms"
                crossOrigin={'false'}
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                }}
                variant="outlined"
              />
            </div>
            <div className="border-2 rounded-lg h-96 overflow-auto">
              {kompassLines && (
                <>
                  {sortGeneral(
                    filterBySearch(
                      filterFocusOptions(filterByMyComms(kompassLines)),
                      searchQuery
                    ),
                    filterCol
                  ) // this is slowing down the component a lot. Probably worth offloading it.
                    .map((focus_option, index) => {
                      return (
                        <div className="p-1" key={index + '_filter_button'}>
                          <Button
                            placeholder={'.'}
                            p-1
                            variant="text"
                            onClick={() => {
                              setFilterDrawer(false)
                              setFilterFocus(focus_option)
                            }}
                          >
                            {focus_option}
                          </Button>
                        </div>
                      )
                    })}
                </>
              )}
            </div>
          </div>
        </div>
      </Drawer>
      <Dialog
        placeholder="."
        open={dialogOpen}
        handler={handleDialog}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xl"
        className="max-h-fit"
      >
        <DialogHeader placeholder={'.'}>Upload New Kompass Sheet</DialogHeader>
        <DialogBody placeholder={'.'}>
          <div className="p-6">
            <div className="py-2 w-8">
              <Input
                crossOrigin={'false'}
                type="text"
                label="Kroger Fiscal Year"
                onChange={handleKrogerYear}
                value={krogerYear}
              />
            </div>
            {krogerYear.length === 4 && (
              <Input
                crossOrigin={'false'}
                type="file"
                label="Kroger's Kompass Sheet"
                onChange={onFileChange}
              />
            )}
            <div className="py-2">
              <Checkbox
                crossOrigin={'false'}
                label="Erase Previous Kompass Lines"
                defaultChecked
                onChange={toggleErasePreviousLines}
              />
            </div>
          </div>
          {parsingUpload && <Loading />}
          {readyToUpload && krogerYear !== '' && (
            <div className="px-10">
              <Button onClick={handleUpload} placeholder=".">
                Upload
              </Button>
            </div>
          )}
        </DialogBody>
      </Dialog>
    </StandardLayout>
  )
}
