import { GlobeAmericasIcon } from '@heroicons/react/24/outline'
import { ring2 } from 'ldrs'
import { AnimatePresence, motion } from 'framer-motion'

export default function FullPageLoading() {
  ring2.register()

  return (
    <div className="flex h-screen w-screen flex-row justify-center">
      <div className="py-40 justify-center">
        <div className="py-12 text-center">
          <h1 className="font-serif text-4xl text-blue-900">Orbit</h1>
          <hr className="border border-black"></hr>
        </div>
          <motion.div
            key={"motion_effect_for_globe"}
            initial={{ scale: 0 }}
            animate={{ rotate: 0, scale: 1 }}
            transition={{
              type: 'spring',
              stiffness: 60,
              damping: 10,
            }}
            exit={{ opacity: 0, scale: 0 }}
          >
            <GlobeAmericasIcon className="h-24 w-24" key={'loading_globe_icon'}/>
          </motion.div>
      </div>
    </div>
  )
}
