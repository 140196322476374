import { client } from "../types/client";
import { contacts } from "../types/contacts";
import { roles } from "../types/roles";

export default function collectClientDocsWithAccountRef(clients: client[], account_id: string, roles_only=false, contacts_only=false){
  var all_ids: client[] = []
  var client_ids_with_roles_ref: client[] = []
  var client_ids_with_contacts_ref: client[] = []

  clients.forEach((client: client)=>{
    const roles: roles  = client.roles
    Object.keys(roles).forEach((title)=>{
      if (roles[title].includes(account_id)) {
        client_ids_with_roles_ref.push(client)
      }
    })
    const contacts: contacts = client.contacts
    Object.keys(contacts).forEach((title)=>{
      if (contacts[title].includes(account_id)) {
        client_ids_with_contacts_ref.push(client)
      }
    })
  })

  if (roles_only){
    return client_ids_with_roles_ref
  } else if (contacts_only) {
    return client_ids_with_contacts_ref
  } else {
    all_ids = client_ids_with_contacts_ref.concat(client_ids_with_roles_ref)
    // this may include duplicates. Beware.
    return  all_ids
  }
}