import {
  Button,
  Card,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
  Input,
  Typography,
  Avatar,
  MenuList,
  Menu,
  MenuItem,
  MenuHandler,
} from '@material-tailwind/react'
import { useState, useEffect, useContext } from 'react'
import '../../App.css'
import { doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore'
import Loading from '../../components/Loading'
import {
  MagnifyingGlassIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { Link, Navigate } from 'react-router-dom'
import StandardLayout from '../../components/StandardLayout'
import parseChange from '../../functions/parseChange'
import {
  AccountsContext,
  ClientsContext,
  CurrentUserContext,
} from '../../contexts'
import { db } from '../../firebase'
import { client } from '../../types/client'
import { account } from '../../types/account'
import ensureClientType from '../../functions/ensureType/ensureClientType'

export default function EditContacts(props: { reloadData: Function }) {
  // general
  const [firstLoad, setFirstLoad] = useState(true)
  const [redirect, setRedirect] = useState(false)
  const [key, setKey] = useState('')
  const reload = () => {
    setKey(String(Math.round(Math.random() * 100000)))
  }
  const [acctKey, setAcctKey] = useState('')
  const reloadAccountsTable = () => {
    setAcctKey(String(Math.round(Math.random() * 100000)))
  }
  const toggleRedirect = () => {
    setRedirect(true)
  }
  // eslint-disable-next-line
  useEffect(() => {
    // runs if first load, and url has been parsed.
    if (firstLoad === true && clients.length > 0 && accounts.length > 0) {
      const url_tag = window.location.href.split('#').pop()
      if (url_tag) {
        getClient(url_tag)
      } else {
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }
      getPresetContacts()
      setFirstLoad(false)
    }
  })

  const clients: client[] = useContext(ClientsContext)
  const current_account = useContext(CurrentUserContext)
  async function getClient(id: string) {
    const client_ref = doc(db, 'clients', String(id))
    const unsub = onSnapshot(client_ref, (querySnapshot) => {
      if (querySnapshot.exists()) {
        const data = ensureClientType(querySnapshot.data())
        setClient(data)
        setClientArchive(JSON.parse(JSON.stringify(data)))
        setContacts(data.contacts)
      } else {
        // client not found.
      }
    })
  }
  async function saveToClient() {
    var new_client_doc: client = client
    new_client_doc.contacts = contacts
    await parseChange(
      'client',
      client.id,
      clientArchive,
      new_client_doc,
      current_account
    )
    toggleRedirect()
    await setDoc(doc(db, 'clients', client.id), new_client_doc)
    props.reloadData({reload_type: 'clients'})
  }
  const [presetContacts, setPresetContacts] = useState([])
  async function getPresetContacts() {
    const docSnap = await getDoc(doc(db, 'settings', 'contacts'))
    if (docSnap.exists()) {
      var data = docSnap.data()
      setPresetContacts(data.options)
      console.log(data.options)
    }
  }

  // cleint status variables
  const [client, setClient] = useState<client>({
    active: false,
    potential_client: false,
    aliases: [],
    brands: [],
    commission_file: '',
    contacts: {},
    roles: {},
    end_date: '',
    start_date: '',
    file_id: '',
    id: '',
    logo: '',
    name: '',
    tier: '',
    child: '',
  })
  const [clientArchive, setClientArchive] = useState<client>({
    name: '',
    active: false,
    potential_client: false,
    id: '',
    file_id: '',
    commission_file: '',
    tier: '',
    logo: '',
    child: '',
    start_date: '',
    end_date: '',
    aliases: [],
    brands: [],
    contacts: {},
    roles: {},
  })
  // accounts status variables
  const accounts = useContext(AccountsContext)

  // temporary roles object
  const [contacts, setContacts] = useState({})

  // account selector dialog controls
  const [query, setQuery] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedContactTitle, setSelectedContactTitle] = useState('')
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([])
  function addAccount(contact_key) {
    handleDialog()
    setSelectedContactTitle(contact_key)
  }
  function handleDialog() {
    setDialogOpen(!dialogOpen)
  }
  function saveAccountsToContact() {
    for (let i in selectedAccounts) {
      updateContacts(selectedAccounts[i])
    }
    function updateContacts(id) {
      var new_contacts_object = contacts
      if (!new_contacts_object[selectedContactTitle].includes(id)) {
        new_contacts_object[selectedContactTitle].push(id)
        setContacts(new_contacts_object)
        reload()
      }
    }
    reload()
    handleDialog()
    setSelectedAccounts([])
  }
  function exitWithoutSavingAccounts() {
    setSelectedAccounts([])
    handleDialog()
    reload()
  }
  function toggleAccountSelection(id: string) {
    var new_selectedAccounts_object: string[] = selectedAccounts
    if (!selectedAccounts.includes(id)) {
      new_selectedAccounts_object.push(id)
      setSelectedAccounts(new_selectedAccounts_object)
      reloadAccountsTable()
    } else {
      var current_accounts: string[] = selectedAccounts
      var final_accounts: string[] = []
      for (let index in current_accounts) {
        if (current_accounts[index] !== id) {
          final_accounts.push(current_accounts[index])
        }
      }
      new_selectedAccounts_object = final_accounts
      setSelectedAccounts(new_selectedAccounts_object)
    }
  }
  function search() {
    var array_of_accounts: account[] = []
    for (let key in accounts) {
      array_of_accounts.push(accounts[key])
    }
    return array_of_accounts.filter((account) =>
      account.name.toString().toLowerCase().includes(query.toLowerCase())
    )
  }
  function handleQuery(e) {
    setQuery(e.target.value)
  }

  // new Contact Type
  const [contactName, setContactName] = useState('')
  function handleContactName(e) {
    setContactName(e.target.value)
  }
  function createContact() {
    var new_contacts = contacts
    if (new_contacts === undefined) {
      new_contacts = {}
    }
    new_contacts[contactName] = []
    setContacts(new_contacts)
    reload()
  }
  function addExistingContact(new_contact) {
    var new_contacts = contacts
    if (new_contacts === undefined) {
      new_contacts = {}
    }
    new_contacts[new_contact] = []
    setContacts(new_contacts)
    reload()
  }
  function deleteRole(contact_key) {
    var new_contacts = {}
    for (let key in contacts) {
      if (!(key === contact_key)) {
        new_contacts[key] = contacts[key]
      }
    }
    setContacts(new_contacts)
    reload()
  }

  // removing accts / roles
  function removeAccount(acct_id, contact_title) {
    var new_contacts_object = contacts
    var current_accounts: account[] = new_contacts_object[contact_title]
    var final_accounts: account[] = []
    for (let index in current_accounts) {
      if (current_accounts[index] !== acct_id) {
        final_accounts.push(current_accounts[index])
      }
    }
    new_contacts_object[contact_title] = final_accounts
    setContacts(new_contacts_object)
    reload()
  }

  // local components
  function ContactsDisplay() {
    return (
      <>
        {Object.keys(contacts).map((key) => {
          return (
            <div key={'contacts_display_' + key}>
              {contactsBlock(key, contacts[key])}
            </div>
          )
        })}
      </>
    )
  }
  function contactsBlock(key, ids) {
    return (
      <>
        <div className="max-w-sm p-4" key={key + '_contacts_block'}>
          <div className=" flex w-full flex-row">
            <Typography
              placeholder="."
              variant="h5"
              color="blue-gray"
              className="px-4"
            >
              {key}
            </Typography>
            <IconButton
              placeholder="."
              variant="text"
              onClick={() => {
                deleteRole(key)
              }}
            >
              <TrashIcon className="h-5 w-5" />
            </IconButton>
          </div>
          <hr className=" w-full border-2 border-blue-700" />
          <div className="">
            {ids?.map((id) => {
              var account_data = {}
              for (let index in accounts) {
                if (accounts[index].id === String(id)) {
                  account_data = accounts[index]
                }
              }
              try {
                return AccountCard(account_data, id, key)
              } catch {
                console.log('Could Not Render Account #' + id)
                return <></>
              }
            })}
          </div>
          <Button
            placeholder="."
            variant="text"
            className="p-0"
            onClick={() => {
              addAccount(key)
            }}
          >
            <div className="p-2">
              <Card
                placeholder="."
                className=" h-30 flex w-60 flex-row rounded-lg border-2 border-gray-400"
              >
                <div className="w-1/3 p-3">
                  <PlusIcon className="h-10 w-10" />
                </div>
                <div className="w-2/3 flex-grow pl-2 pt-3">
                  <Typography placeholder="." color="blue-gray">
                    Add New Account
                  </Typography>
                </div>
              </Card>
            </div>
          </Button>
        </div>
      </>
    )
  }
  function AccountCard(account, acct_id, role_title) {
    return (
      <div className="p-2 " key={acct_id + '_acct_card_' + role_title}>
        <Card
          placeholder="."
          className=" h-30 flex w-80 flex-row rounded-lg border-2 border-gray-400"
        >
          <div className="w-1/3">
            <Avatar
              placeholder="."
              src={account?.avatar}
              className="h-20 w-20 p-2"
            />
          </div>
          <div className="w-2/3 flex-grow pl-2 pt-3">
            <Typography placeholder="." variant="h5" color="blue-gray">
              <Link to={'/view/account#' + String(acct_id)}>
                {account?.name}
              </Link>
            </Typography>
            <Typography placeholder="." variant="small" color="blue-gray">
              {account?.phone}
            </Typography>
          </div>
          <IconButton
            placeholder="."
            variant="text"
            onClick={() => {
              removeAccount(acct_id, role_title)
            }}
          >
            <TrashIcon className="h-5 w-5" />
          </IconButton>
        </Card>
      </div>
    )
  }

  const TABLE_HEAD = ['Name', 'Phone #', 'Email', 'Title', '']
  const [title, setTitle] = useState('Single Client Contacts')

  return (
    <StandardLayout title={title} reloadData={props.reloadData}>
      <>
        {redirect && <Navigate to={'/view/client#' + client.id} replace />}
        <Card
          placeholder="."
          className="flex h-fit flex-col rounded-sm"
          key={key}
        >
          <Dialog
            placeholder="."
            open={dialogOpen}
            handler={handleDialog}
            animate={{
              mount: { scale: 1, y: 0 },
              unmount: { scale: 0.9, y: -100 },
            }}
            size="xl"
          >
            <DialogHeader placeholder=".">
              <div className="flex w-full justify-between p-0">
                <div>Select Accounts</div>
                <div>
                  <Link
                    to="/add/account"
                  >
                    <Button placeholder="." variant="outlined">
                      Add New Account
                    </Button>
                  </Link>
                </div>
              </div>
            </DialogHeader>
            <DialogBody placeholder="." className="h-96 overflow-auto">
              <div className="w-50 flex flex-row pb-5">
                <Input
                  crossOrigin={false}
                  label="Search"
                  value={query}
                  onChange={handleQuery}
                  icon={<MagnifyingGlassIcon />}
                />
              </div>
              <table
                className="w-full table-auto overflow-auto border-2 text-left"
                key={acctKey}
              >
                <thead>
                  <tr key={'header_row'}>
                    {TABLE_HEAD.map((head, index) => (
                      <th
                        key={head + index}
                        className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                      >
                        <Typography
                          placeholder="."
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                {accounts !== undefined ? (
                  <tbody>
                    {search().map((account, index) => {
                      var classes = ''
                      if (selectedAccounts.includes(account.id)) {
                        classes = 'bg-blue-100'
                      } else {
                        classes = ''
                      }
                      return (
                        <tr
                          key={account.name + String(index)}
                          className={classes}
                        >
                          <td className="p-4">
                            <Typography
                              placeholder="."
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {account?.name}
                            </Typography>
                          </td>
                          <td className="p-4">
                            <Typography
                              placeholder="."
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {account.phone}
                            </Typography>
                          </td>
                          <td className="p-4">
                            <Typography
                              placeholder="."
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {account.email}
                            </Typography>
                          </td>
                          <td className="p-4">
                            <Typography
                              placeholder="."
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {account.title}
                            </Typography>
                          </td>
                          <td className={` bg-blue-gray-50/50`}>
                            <div className="flex w-full justify-center pl-2">
                              <Button
                                placeholder="."
                                variant="text"
                                color="blue"
                                onClick={() => {
                                  toggleAccountSelection(account.id)
                                }}
                              >
                                Select
                              </Button>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                ) : (
                  <>
                    <div className="p-3">
                      <Loading />
                    </div>
                  </>
                )}
              </table>
            </DialogBody>
            <DialogFooter placeholder=".">
              <Button
                placeholder="."
                variant="text"
                onClick={exitWithoutSavingAccounts}
              >
                <span>Close without Saving</span>
              </Button>
              <Button
                placeholder="."
                variant="gradient"
                color="green"
                onClick={saveAccountsToContact}
              >
                <span>Add Accounts to Role</span>
              </Button>
            </DialogFooter>
          </Dialog>
          {client === undefined ? (
            <div className="p-6">
              <Loading />
            </div>
          ) : (
            <div className="flex h-full w-full flex-row flex-wrap">
              <div className="w-8/12 max-w-max p-4 pt-8">
                <div className="flex flex-col flex-wrap">
                  <Typography placeholder="." variant="h4" className="pb-2">
                    Contacts
                  </Typography>
                  <hr></hr>
                  <div className="flex flex-row flex-wrap">
                    {client !== undefined && contacts !== undefined && (
                      <ContactsDisplay />
                    )}
                    <div className="max-w-sm p-4" key="add_row_input">
                      <Typography
                        placeholder="."
                        variant="h5"
                        color="blue-gray"
                        className="px-4"
                      >
                        Add Contact
                      </Typography>
                      <hr className=" w-full border-2 border-green-700" />
                      <div className="flex flex-row p-2">
                        <Menu>
                          <MenuHandler>
                            <Button placeholder="." variant="outlined">
                              Choose from Presets
                            </Button>
                          </MenuHandler>
                          <MenuList placeholder=".">
                            {presetContacts.map((contact, index) => {
                              return (
                                <MenuItem
                                  placeholder="."
                                  key={index + '_contact_option'}
                                  onClick={() => {
                                    addExistingContact(contact)
                                  }}
                                >
                                  {contact}
                                </MenuItem>
                              )
                            })}
                          </MenuList>
                        </Menu>
                      </div>
                    </div>
                    <div className="max-w-sm p-4" key="add_custom_row_input">
                      <Typography
                        placeholder="."
                        variant="h5"
                        color="blue-gray"
                        className="px-4"
                      >
                        Add Custom Contact
                      </Typography>
                      <hr className=" w-full border-2 border-green-700" />
                      <div className="flex flex-row p-2">
                        <Input
                          crossOrigin={false}
                          value={contactName}
                          onChange={handleContactName}
                          label="New Contact Title"
                        ></Input>
                        <IconButton
                          placeholder="."
                          className="p-1"
                          variant="text"
                          onClick={createContact}
                        >
                          <PlusIcon className="h-5 w-5"></PlusIcon>
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="w-fit p-4">
            <Button
              placeholder="."
              onClick={saveToClient}
              className="w-1/6 min-w-fit"
            >
              Submit Changes
            </Button>
          </div>
        </Card>
      </>
    </StandardLayout>
  )
}
