import { useEffect, useState } from 'react'
import logo from '../../assets/img/osmg-logo-without-subtitle.png'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { Link, Navigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Input,
  Typography,
} from '@material-tailwind/react'
import { TurnstileWidget } from '../../components/TurnstileWidget'

function SignIn() {
  const [signedIn, setSignIn] = useState(false)
  const [failedSignIn, setFailSignIn] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const auth = getAuth()

  function handleSignIn(email, password) {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user
        console.log('User is logged in: ' + String(user.email))
        setSignIn(true)
        window.location.reload()
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log(String(errorCode) + String(errorMessage))
        setFailSignIn(true)
      })
  }
  const handleChangeUsr = (event) => {
    setUsername(sanitize(event.target.value))
  }
  const handleChangePswd = (event) => {
    setPassword(sanitize(event.target.value))
  }
  const handleSubmit = () => {
    handleSignIn(username, password)
  }
  const [captchaValid, setCaptchaValid] = useState(false)
  const [testingEnv, setTestingEnv] = useState(false)
  useEffect(() => {
    // bypass captcha if testing locally.
    if (!captchaValid) {
      if (window.location.href.substring(0, 16) === 'http://localhost') {
        setCaptchaValid(true)
        setTestingEnv(true)
      }
      if (
        window.location.href.includes('https://osmg-internal--') &&
        window.location.href.includes('web.app')
      ) {
        setCaptchaValid(true)
        setTestingEnv(true)
      }
    }
  })

  function sanitize(string) {
    const map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#x27;',
      '/': '&#x2F;',
      '`': '&grave;',
    }
    const reg = /[&<>"'/]/gi
    return string.replace(reg, (match) => map[match])
  }
  return (
    <div className="flex h-screen w-screen flex-col bg-gradient-to-br from-blue-900 to-blue-800">
      {signedIn ? <Navigate to="/" replace /> : <></>}
      <div className="h-1/8 flex flex-1 flex-row-reverse">
        <img
          src={logo}
          style={{ width: '240px', height: '100px', padding: '20px' }}
        ></img>
      </div>
      <div
        className="flex h-full w-full flex-col justify-center p-10"
        style={{ background: `linear-gradient(transparent 50%, #ffffff 50%)` }}
      >
        <div className=" flex w-full flex-row justify-center align-top">
          <Card placeholder="." className="min-w-60">
            <CardBody placeholder="." className="flex flex-col">
              <div className="p-2">
                <h1 className=" text-center font-serif text-2xl font-semibold">
                  User Login
                </h1>
              </div>
              <hr></hr>
              <div>
                {failedSignIn ? (
                  <p className=" px-2 pb-5 pt-8 text-red-500">
                    Failed to sign in. Wrong username or password.
                  </p>
                ) : (
                  <></>
                )}
              </div>
              <div className="p-6">
                <Input
                  crossOrigin={false}
                  variant="standard"
                  label="Username / Email"
                  type="text"
                  size="lg"
                  value={username}
                  onChange={handleChangeUsr}
                />
              </div>
              <div className="p-6">
                <Input
                  crossOrigin={false}
                  label="Password"
                  variant="standard"
                  size="lg"
                  value={password}
                  onChange={handleChangePswd}
                  type="password"
                />
              </div>
              <div className="w-full p-6 pt-8">
                {captchaValid ? (
                  <Button
                    placeholder="."
                    onClick={handleSubmit}
                    className="w-full"
                    variant="gradient"
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    placeholder="."
                    className="w-full"
                    variant="gradient"
                    disabled
                  >
                    Submit
                  </Button>
                )}
              </div>
              <div className="px-8">
                <div className="justify-left flex w-full flex-grow">
                  <Typography placeholder="." variant="small">
                    <Link to="/reset/password">Forgot password?</Link>
                  </Typography>
                </div>
                <div className="justify-left flex w-full flex-grow py-2">
                  <Typography placeholder="." variant="small">
                    <Link to="/reset/password#new">First time logging in?</Link>
                  </Typography>
                </div>
                {!testingEnv && (
                  <div className="pt-4">
                    <TurnstileWidget
                      setValidResponse={() => setCaptchaValid(true)}
                    />
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default SignIn
