import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
  Card,
  Checkbox,
  Chip,
  IconButton,
  Input,
  Radio,
  Typography,
} from '@material-tailwind/react'
import React, { useContext, useEffect, useState } from 'react'
import '../../App.css'
import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline'
import Loading from '../../components/Loading'
import { Link, Navigate } from 'react-router-dom'
import StandardLayout from '../../components/StandardLayout'
import downloadDetails from '../../functions/downloadDetails'
import {
  AccountsContext,
  ClientsContext,
  PermissionsContext,
} from '../../contexts'
import { permission } from '../../types/permission'
import { account } from '../../types/account'
import { client } from '../../types/client'
import useWindowSize from '../../functions/useWindowSize'
import { refreshIFMisaligned } from '../../functions/refreshIfMisaligned'

export default function Clients(props: { reloadData: Function }) {
  const clients: client[] = useContext(ClientsContext)
  const accounts: account[] = useContext(AccountsContext)
  const permissions: permission = useContext(PermissionsContext)
  const [query, setQuery] = useState<string>('')
  const [searchOpen, setSearchOpen] = useState<boolean>(false)
  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [searchKey, setSearchKey] = useState<string>('all')
  const [filters, setFilters] = useState<string>('active')
  const [sortOption, setSortOption] = useState<string>('A-Z')
  interface exportOptionsType {
    filter: boolean
    sort: boolean
    search: boolean
  }
  function handleSearchOpen() {
    setSearchOpen(!searchOpen)
  }
  function handleFilterOpen() {
    setFilterOpen(!filterOpen)
  }
  function handleChangeSearchBy(new_key) {
    setSearchKey(new_key)
  }
  function handleChangeSortBy(new_key) {
    setSortOption(new_key)
  }
  function handleChangeFilter(new_key) {
    setFilters(new_key)
  }
  function handleQuery(e) {
    setQuery(e.target.value)
  }
  function search(clients: client[]) {
    // there are three stages to the searching/sorting/filtering process
    // first is checks by search via the field selected by the user. .replace(/\D/g, '')
    // second it sorts the results by either A-Z or Z-A
    // finally it filters out based on of internal or external.
    return clients
      .filter((client: client) => {
        if (searchKey !== 'all') {
          return client[searchKey]
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase())
        } else {
          var response = client['name']
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase())
          if (response === false) {
            response = client['aliases']
              .toString()
              .toLowerCase()
              .includes(query.toLowerCase())
          }
          if (response === false) {
            response = client['brands']
              .toString()
              .toLowerCase()
              .includes(query.toLowerCase())
          }
          if (response === false) {
            response = client['file_id'].toString().includes(query)
          }
          return response
        }
      })
      .sort((a: client, b: client) => {
        var a_date_array: string[]
        var b_date_array: string[]
        var a_date_array_num: number[] = []
        var b_date_array_num: number[] = []
        if (sortOption === 'A-Z') {
          return a.name.localeCompare(b.name)
        }
        if (sortOption === 'Z-A') {
          return b.name.localeCompare(a.name)
        }
        if (sortOption === 'date-ascending') {
          try {
            a_date_array = a.start_date.split('/')
            b_date_array = b.start_date.split('/')
            for (let i in a_date_array) {
              a_date_array_num.push(Number(a_date_array[i]))
            }
            for (let i in b_date_array) {
              b_date_array_num.push(Number(b_date_array[i]))
            }
            if (a_date_array_num.length === 1) {
              if (b_date_array_num.length === 1) {
                return 0
              } else {
                return 1
              }
            } else if (b_date_array_num.length === 1) {
              return -1
            }
            if (a_date_array_num[2] !== b_date_array_num[2]) {
              return a_date_array_num[2] - b_date_array_num[2]
            } else if (a_date_array_num[0] !== b_date_array_num[0]) {
              return a_date_array_num[0] - b_date_array_num[0]
            } else {
              return a_date_array_num[1] - b_date_array_num[1]
            }
          } catch (err) {
            return -1
          }
        } else if (sortOption === 'date-descending') {
          try {
            a_date_array = a.start_date.split('/')
            b_date_array = b.start_date.split('/')
            for (let i in a_date_array) {
              a_date_array_num[i] = Number(a_date_array[i])
            }
            for (let i in b_date_array) {
              b_date_array_num[i] = Number(b_date_array[i])
            }
            if (a_date_array_num.length === 1) {
              if (b_date_array_num.length === 1) {
                return 0
              } else {
                return 1
              }
            } else if (b_date_array_num.length === 1) {
              return -1
            }
            if (b_date_array_num[2] !== a_date_array_num[2]) {
              return b_date_array_num[2] - a_date_array_num[2]
            } else if (b_date_array_num[0] !== a_date_array_num[0]) {
              return b_date_array_num[0] - a_date_array_num[0]
            } else {
              return b_date_array_num[1] - a_date_array_num[1]
            }
          } catch (err) {
            return -1
          }
        } else {
          return 1
        }
      })
      .filter((client: client) => {
        if (filters === 'active') {
          return client.active
        }
        if (filters === 'in-active') {
          return !client.active
        } else {
          return true
        }
      })
  }
  const size = useWindowSize()

  const pc_mail_min_width = 1750
  const pc_phone_min_width = 1500
  const pc_name_min_width = 1200

  const TABLE_HEAD = [
    'Name',
    'ID',
    'Active',
    'Start Date',
    'End Date',
    'Primary Contact',
    'Primary Contact Phone',
    'Primary Contact Email',
  ]

  const [navigate, setNavigate] = useState('')

  const [firstLoad, setFirstLoad] = useState(true)
  const [dataReady, setDataReady] = useState(false)
  useEffect(() => {
    if (firstLoad) {
      checkStatus()
      setFirstLoad(false)
    }
  })
  async function checkStatus() {
    await refreshIFMisaligned('clients', clients, props.reloadData)
    await refreshIFMisaligned('accounts', accounts, props.reloadData)
    setDataReady(true)
  }

  return (
    <StandardLayout title="Clients" reloadData={props.reloadData}>
      {navigate !== '' && <Navigate replace to={navigate} />}
      <Card
        placeholder="."
        className="flex w-fit max-w-full flex-row rounded-lg overflow-clip"
        style={{ height: '90vh' }}
      >
        <div className="flex w-full flex-grow basis-2/3 flex-col p-5">
          <div className="flew-row flex w-full justify-between">
            <div className="w-96">
              <Input
                crossOrigin={false}
                label="Search"
                value={query}
                onChange={handleQuery}
                icon={<MagnifyingGlassIcon />}
              />
            </div>
            {permissions.add_clients !== undefined &&
              permissions.add_clients === true && (
                <div className="w-fit px-2">
                  <Link to="/add/client">
                    <Button placeholder="." variant="text">
                      Add Client
                    </Button>
                  </Link>
                </div>
              )}
          </div>
          <div className="py-5 h-full">
            {!dataReady ? (
              <div>
                <Loading />
              </div>
            ) : (
              <Card
                placeholder="."
                className="max-h-full pb-2 w-full overflow-auto rounded-lg border border-gray-300 shadow-md"
              >
                <table className="w-full table-auto text-left">
                  <thead>
                    <tr>
                      {TABLE_HEAD.map((head) => {
                        if (
                          head === 'Primary Contact' &&
                          size.width < pc_name_min_width
                        ) {
                          return <></>
                        }
                        if (
                          head === 'Primary Contact Phone' &&
                          size.width < pc_phone_min_width
                        ) {
                          return <></>
                        }
                        if (
                          head === 'Primary Contact Email' &&
                          size.width < pc_mail_min_width
                        ) {
                          return <></>
                        }
                        return (
                          <th
                            key={head}
                            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                          >
                            <Typography
                              placeholder="."
                              variant="small"
                              color="blue-gray"
                              className="font-normal leading-none opacity-70"
                            >
                              {head}
                            </Typography>
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  {clients !== undefined ? (
                    <tbody>
                      {search(clients).map((client, index) => {
                        const classes =
                          'p-2 border-b border-blue-gray-50 overflow-auto' // table-fixed w-36 overflow-hidden
                        var primaryContact = [<></>]
                        var email = [<></>]
                        var phone = [<></>]
                        if (
                          client.contacts['Primary Contact'] !== undefined &&
                          client.contacts['Primary Contact'].length > 0
                        ) {
                          var counter = 0
                          for (let i in accounts) {
                            if (
                              client.contacts['Primary Contact'].includes(
                                accounts[i].id
                              )
                            ) {
                              if (counter > 0) {
                                primaryContact.push(
                                  <>
                                    <br />
                                    <div>{accounts[i].name}</div>
                                  </>
                                )
                                email.push(
                                  <>
                                    <br />
                                    <div>{accounts[i].email}</div>
                                  </>
                                )
                                phone.push(
                                  <>
                                    <br />
                                    <div>{accounts[i].phone}&nbsp;</div>
                                  </>
                                )
                              } else {
                                primaryContact.push(
                                  <div>{accounts[i].name}</div>
                                )
                                phone.push(<div>{accounts[i].phone}&nbsp;</div>)
                                email.push(<div>{accounts[i].email}</div>)
                              }
                              counter += 1
                            }
                          }
                        }

                        return (
                          <tr
                            key={client.name + String(index)}
                            className=" hover:bg-blue-50"
                            onClick={() => {
                              setNavigate('/view/client#' + String(client.id))
                            }}
                          >
                            <td className={classes}>
                              <Typography
                                placeholder="."
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {client?.name}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                placeholder="."
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {client?.file_id}
                              </Typography>
                            </td>
                            <td className={`${classes} `}>
                              <Typography
                                placeholder="."
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {client?.potential_client ? (
                                  <Chip
                                    variant="ghost"
                                    color="blue"
                                    size="sm"
                                    value="Potential"
                                    className=" text-center"
                                  />
                                ) : (
                                  <>
                                    {client?.active ? (
                                      <Chip
                                        variant="ghost"
                                        color="green"
                                        size="sm"
                                        value="Active"
                                        className=" text-center"
                                      />
                                    ) : (
                                      <Chip
                                        variant="ghost"
                                        size="sm"
                                        value="In-Active"
                                        color="red"
                                        className=" text-center"
                                      />
                                    )}
                                  </>
                                )}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                placeholder="."
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {client.start_date}
                              </Typography>
                            </td>

                            <td className={classes}>
                              <Typography
                                placeholder="."
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {client.end_date}
                              </Typography>
                            </td>
                            {size.width > pc_name_min_width && (
                              <td className={classes + 'w-14 table-fixed'}>
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {primaryContact}
                                </Typography>
                              </td>
                            )}
                            {size.width > pc_phone_min_width && (
                              <td className={classes + 'w-14 table-fixed'}>
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {phone}
                                </Typography>
                              </td>
                            )}
                            {size.width > pc_mail_min_width && (
                              <td className={classes + 'w-14 table-fixed'}>
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {email}
                                </Typography>
                              </td>
                            )}
                          </tr>
                        )
                      })}
                    </tbody>
                  ) : (
                    <>
                      <div className="flex flex-col justify-start p-20">
                        <Loading />
                      </div>
                    </>
                  )}
                </table>
              </Card>
            )}
          </div>
        </div>
        <div className="w-fit flex-grow p-5">
          <div>
            <div className="w-full">
              <Accordion placeholder="." open={searchOpen}>
                <AccordionHeader placeholder="." onClick={handleSearchOpen}>
                  Search By
                </AccordionHeader>
                <AccordionBody className="w-full">
                  <div className="flex max-h-64 w-full flex-col overflow-auto p-2">
                    <Radio
                      crossOrigin={false}
                      name="search_by"
                      onClick={() => {
                        handleChangeSearchBy('all')
                      }}
                      label="All"
                    />
                    <Radio
                      crossOrigin={false}
                      name="search_by"
                      onClick={() => {
                        handleChangeSearchBy('name')
                      }}
                      label="Name"
                      defaultChecked
                    />
                    <Radio
                      crossOrigin={false}
                      name="search_by"
                      onClick={() => {
                        handleChangeSearchBy('aliases')
                      }}
                      label="Alias"
                    />
                    <Radio
                      crossOrigin={false}
                      name="search_by"
                      onClick={() => {
                        handleChangeSearchBy('brands')
                      }}
                      label="Brand"
                    />
                    <Radio
                      crossOrigin={false}
                      name="search_by"
                      onClick={() => {
                        handleChangeSearchBy('file_id')
                      }}
                      label="ID"
                    />
                  </div>
                  <hr />
                </AccordionBody>
              </Accordion>
            </div>
            <div className="w-full">
              <Accordion placeholder="." open={filterOpen}>
                <AccordionHeader placeholder="." onClick={handleFilterOpen}>
                  Sort & Filter
                </AccordionHeader>
                <AccordionBody className="w-full">
                  <div className="flex flex-row">
                    <div className="flex flex-col p-2">
                      <strong>Filter:</strong>
                      <Radio
                        crossOrigin={false}
                        name="filterCheck"
                        onClick={() => {
                          handleChangeFilter('active')
                        }}
                        label="Active"
                        defaultChecked
                      />
                      <Radio
                        crossOrigin={false}
                        name="filterCheck"
                        onClick={() => {
                          handleChangeFilter('in-active')
                        }}
                        label="In-Active"
                      />
                      <Radio
                        crossOrigin={false}
                        name="filterCheck"
                        onClick={() => {
                          handleChangeFilter('')
                        }}
                        label="No Filters"
                      />
                    </div>
                    <div className="flex flex-col p-2">
                      <strong>Sort:</strong>
                      <Radio
                        crossOrigin={false}
                        name="sortBy"
                        onClick={() => {
                          handleChangeSortBy('A-Z')
                        }}
                        label="A-Z"
                        defaultChecked
                      />
                      <Radio
                        crossOrigin={false}
                        name="sortBy"
                        onClick={() => {
                          handleChangeSortBy('Z-A')
                        }}
                        label="Z-A"
                      />
                      <Radio
                        crossOrigin={false}
                        name="sortBy"
                        onClick={() => {
                          handleChangeSortBy('date-ascending')
                        }}
                        label="Date Ascending"
                      />
                      <Radio
                        crossOrigin={false}
                        name="sortBy"
                        onClick={() => {
                          handleChangeSortBy('date-descending')
                        }}
                        label="Date Descending"
                      />
                    </div>
                  </div>
                  <hr />
                </AccordionBody>
              </Accordion>
            </div>
          </div>
        </div>
      </Card>
    </StandardLayout>
  )
}
