import { account } from "../types/account";

export default function swapValueForNameIfAccountID(value: string, accounts: account[]) {
  var return_value = value

  accounts.forEach((account:account)=>{
    if (value === account.id) {
      return_value = account.name
    }
  })
  if (return_value !== "") {
  return return_value} else {
    return "Unknown"
  }
}