import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useEffect, useState } from 'react'
import './index.css'
import NotFound from './pages/public/NotFound'
import SignIn from './pages/public/SignIn'
import { Navigate } from 'react-router-dom'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import {
  AccountsContext,
  ChangesContext,
  ClientsContext,
  CurrentUserContext,
  PermissionsContext,
} from './contexts'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
} from 'firebase/firestore'
import { db } from './firebase'
import { account } from './types/account'
import { client } from './types/client'
import { change } from './types/change'
import { permission } from './types/permission'
import Home from './pages/Home'
import Accounts from './pages/accounts/Accounts'
import Clients from './pages/clients/Cleints'
import EditContacts from './pages/clients/EditContacts'
import EditRoles from './pages/clients/EditRoles'
import EditClient from './pages/clients/EditClient'
import EditAccount from './pages/accounts/EditAccount'
import AddClient from './pages/clients/AddClient'
import AddAccount from './pages/accounts/AddAccount'
import SingleClient from './pages/clients/SingleCleint'
import { Settings } from './pages/settings/Settings'
import ChangeLog from './pages/ChangeLog'
import About from './pages/About'
import Editor from './pages/Editor'
import ResetPassword from './pages/public/ResetPassword'
import EditUPCs from './components/EditUPCs'
import FAQ from './pages/FAQ'
import SubmitFeedback from './pages/SubmitFeedback'
import ReportBug from './pages/ReportBug'
import Exports from './pages/exports/Exports'
import AccountPageHandler from './pages/accounts/AccountPageHandler'
import KompassHandler from './pages/KompassHandler'
import NotificationPreferences from './pages/NotificationsPreferences'
import { setHash } from './functions/refreshIfMisaligned'

export default function App() {
  // Initialize Firebase and Auth
  const auth = getAuth()
  const [loggedIn, setLoggedIn] = useState<boolean>(false)
  const [UID, setUID] = useState('')
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUID(user.uid)
      setLoggedIn(true)
    } else {
      console.log('User is signed out.')
      // prob open new page here??
      setLoggedIn(false)
    }
  })

  // Setup Listener for Current User's Account and Permissions.
  const [currentUserAccount, setUserAccount] = useState<account>({
    name: '',
    phone: '',
    email: '',
    title: '',
    internal: true,
    company: '',
    avatar: '',
    id: '',
  })
  const [permissions, setPermissions] = useState<permission>({
    title: 'Default',
    add_accounts: false,
    edit_accounts: false,
    edit_my_clients_accounts: false,
    edit_current_user_account: false,
    add_clients: false,
    edit_all_clients: false,
    edit_assigned_clients: false,
    view_tiers: false,
    edit_payments: false,
    view_payments: false,
    view_my_client_payments: false,
    edit_all_roles: false,
    edit_my_client_roles: false,
    edit_all_contacts: false,
    edit_my_client_contacts: false,
    view_settings: false,
    edit_presets: false,
    edit_marketing: false,
    view_marketing: false,
  })
  const [fetchedData, setFetchedData] = useState(false)
  const [status, setStatus] = useState('offline')
  async function getSiteStatus() {
    await getDoc(doc(db, 'settings', 'status')).then((doc) => {
      if (doc.exists()) {
        const data = doc.data()
        if (data.servicing) {
          setStatus('servicing')
        } else if (data.error) {
          setStatus('error')
        } else {
          setStatus('')
        }
        if (data.latest_version) {
          // setLatestVersion(data.latest_version) # TODO still in development.
        }
      }
    })
  }
  async function getAccount() {
    const user = auth.currentUser
    if (user !== null) {
      const accountsRef = collection(db, 'accounts')
      const q = query(
        accountsRef,
        where('email', '==', user.email),
        where('internal', '==', true)
      )
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        var response_accts: account[] = []
        querySnapshot.forEach((doc) => {
          const data = doc.data()
          var account: account = {
            name: '',
            phone: '',
            email: '',
            title: '',
            internal: true,
            company: '',
            avatar: '',
            id: '',
          }
          if (data) {
            const keys = Object.keys(data)
            for (let i in keys) {
              try {
                account[keys[i]] = data[keys[i]]
              } catch (err) {
                console.log(
                  'There was an error copying a portion of your account information.',
                  err
                )
              }
            }
            response_accts.push(account)
          }
        })
        if (response_accts.length === 1) {
          setUserAccount(response_accts[0])
          setDoc(doc(db, 'users', UID), { id: response_accts[0].id })
          if (
            response_accts[0].UID === undefined ||
            response_accts[0].UID === ''
          ) {
            setDoc(
              doc(db, 'accounts', response_accts[0].id),
              { UID: UID },
              { merge: true }
            )
          }
        } else {
          // error finding account.
        }
      })
    } else {
      console.log('User is Null.')
    }
  }
  async function getPermissions() {
    const user = auth.currentUser
    if (user !== null) {
      const querySnapshot = await getDoc(doc(db, 'permissions', user.uid))
      if (querySnapshot.exists()) {
        var permission: permission = {
          title: 'Default',
          add_accounts: false,
          edit_accounts: false,
          edit_my_clients_accounts: false,
          edit_current_user_account: false,
          add_clients: false,
          edit_all_clients: false,
          edit_assigned_clients: false,
          view_tiers: false,
          edit_payments: false,
          view_payments: false,
          view_my_client_payments: false,
          edit_all_roles: false,
          edit_my_client_roles: false,
          edit_all_contacts: false,
          edit_my_client_contacts: false,
          view_settings: false,
          edit_presets: false,
          edit_marketing: false,
          view_marketing: false,
        }
        const data = querySnapshot.data()
        if (data) {
          const keys = Object.keys(data)
          for (let i in keys) {
            permission[keys[i]] = data[keys[i]]
          }
        }
        console.log(permission)
        setPermissions(permission)
        console.log('User is Admin.')
      } else {
        console.log('User is not admin.')
      }
    } else {
      console.log('User is Null.')
    }
  }

  // Setup Static Records of all Accounts and Clients
  const [clients, setClients] = useState<client[]>([])
  async function getClients() {
    const clients_collection = collection(db, 'clients')
    const querySnapshot = await getDocs(clients_collection)
    var clients_temp: client[] = []
    querySnapshot.forEach((doc) => {
      var client: client = {
        name: '',
        active: false,
        potential_client: false,
        id: '',
        file_id: '',
        commission_file: '',
        tier: '',
        logo: '',
        child: '',
        start_date: '',
        end_date: '',
        aliases: [],
        brands: [],
        contacts: {},
        roles: {},
      }
      const data = doc.data()
      if (data) {
        const keys = Object.keys(data)
        for (let i in keys) {
          client[keys[i]] = data[keys[i]]
        }
        clients_temp.push(client)
      }
    })
    await setHash('clients', clients_temp)
    setClients(clients_temp)
  }
  const [accounts, setAccounts] = useState<account[]>([])
  async function getAccounts() {
    const accounts_collection = collection(db, 'accounts')
    const querySnapshot = await getDocs(accounts_collection)
    var accounts_temp: account[] = []
    querySnapshot.forEach((doc) => {
      var account: account = {
        name: '',
        phone: '',
        email: '',
        title: '',
        internal: true,
        company: '',
        avatar: '',
        id: '',
      }
      const data = doc.data()
      if (data) {
        const keys = Object.keys(data)
        for (let i in keys) {
          account[keys[i]] = data[keys[i]]
        }
        accounts_temp.push(account)
      }
    })
    await setHash('accounts', accounts_temp)
    setAccounts(accounts_temp)
  }

  if (window.location.href.includes('osmg-internal.web.app')) {
    alert(
      'You are accessing Orbit through an old URL which has been decomissioned to improve security.\nYou are going to be redirected to the new site.'
    )
    window.location.href = 'https://osmg.app'
  }

  // eslint-disable-next-line
  useEffect(() => {
    if (loggedIn && !fetchedData) {
      getSiteStatus()
      getAccount()
      getClients()
      getAccounts()
      getPermissions()
      setFetchedData(true)
    }
  }, [loggedIn, fetchedData])

  async function reloadFromFirebase(config: reloadFromFirebaseProps) {
    if (config === undefined) {
      console.log('Reload from firebase was run.')
      await getClients()
      await getAccounts()
      await getPermissions()
    } else {
      if (config.reload_type === 'accounts') {
        await getAccounts()
      }
      if (config.reload_type === 'clients') {
        await getClients()
      }
      if (config.reload_type === 'permissions') {
        await getPermissions()
      }
    }
  }
  return (
    <BrowserRouter>
      <CurrentUserContext.Provider value={currentUserAccount}>
        <PermissionsContext.Provider value={permissions}>
          <ClientsContext.Provider value={clients}>
            <AccountsContext.Provider value={accounts}>
              {clients && accounts && (
                <Routes>
                  <Route
                    path="/"
                    element={
                      loggedIn ? <Navigate to="home" replace /> : <SignIn />
                    }
                  />
                  {status && (
                    <Route
                      path={'/404'}
                      Component={() => <NotFound status={status} />}
                    />
                  )}
                  <Route
                    path={'/reset/password'}
                    Component={() => <ResetPassword />}
                  />
                  {loggedIn && (
                        <>
                          <Route
                            path="/home"
                            Component={(props) => (
                              <Home reloadData={reloadFromFirebase} />
                            )}
                          />
                          <Route
                            path="/accounts"
                            Component={(props) => (
                              <Accounts reloadData={reloadFromFirebase} />
                            )}
                          />
                          <Route
                            path="/clients"
                            Component={(props) => (
                              <Clients reloadData={reloadFromFirebase} />
                            )}
                          />
                          <Route
                            path="/kompass"
                            Component={(props) => (
                              <KompassHandler reloadData={reloadFromFirebase} />
                            )}
                          />
                          {permissions.edit_all_roles && (
                            <Route
                              path="/editor"
                              Component={(props) => (
                                <Editor reloadData={reloadFromFirebase} />
                              )}
                            />
                          )}
                          <Route
                            path="/export"
                            Component={(props) => (
                              <Exports reloadData={reloadFromFirebase} />
                            )}
                          />
                          <Route
                            path="/changelog"
                            Component={(props) => (
                              <ChangeLog reloadData={reloadFromFirebase} />
                            )}
                          />
                          {permissions.view_settings && (
                            <Route
                              path="/settings"
                              Component={(props) => (
                                <Settings reloadData={reloadFromFirebase} />
                              )}
                            />
                          )}
                          <Route
                            path="/view/account"
                            Component={(props) => (
                              <AccountPageHandler
                                reloadData={reloadFromFirebase}
                              />
                            )}
                          />
                          <Route
                            path="/view/client"
                            Component={(props) => (
                              <SingleClient reloadData={reloadFromFirebase} />
                            )}
                          />
                          <Route
                            path="/view/user"
                            Component={(props) => (
                              <AccountPageHandler
                                reloadData={reloadFromFirebase}
                              />
                            )}
                          />
                          {permissions.add_accounts !== undefined &&
                            permissions.add_accounts === true && (
                              <Route
                                path="/add/account"
                                Component={(props) => (
                                  <AddAccount reloadData={reloadFromFirebase} />
                                )}
                              />
                            )}
                          <Route
                            path="/add/client"
                            Component={(props) => (
                              <AddClient reloadData={reloadFromFirebase} />
                            )}
                          />
                          <Route
                            path="/edit/account"
                            Component={(props) => (
                              <EditAccount reloadData={reloadFromFirebase} />
                            )}
                          />
                          <Route
                            path="/edit/client"
                            Component={(props) => (
                              <EditClient reloadData={reloadFromFirebase} />
                            )}
                          />
                          <Route
                            path="/edit/client/roles"
                            Component={(props) => (
                              <EditRoles reloadData={reloadFromFirebase} />
                            )}
                          />
                          <Route
                            path="/edit/client/contacts"
                            Component={(props) => (
                              <EditContacts reloadData={reloadFromFirebase} />
                            )}
                          />
                          <Route
                            path="/edit/client/upcs"
                            Component={(props) => (
                              <EditUPCs reloadData={reloadFromFirebase} />
                            )}
                          />
                          <Route
                            path="about"
                            Component={(props) => (
                              <About reloadData={reloadFromFirebase} />
                            )}
                          />
                          <Route
                            path="faq"
                            Component={(props) => (
                              <FAQ reloadData={reloadFromFirebase} />
                            )}
                          />
                          <Route
                            path="feedback"
                            Component={(props) => (
                              <SubmitFeedback reloadData={reloadFromFirebase} />
                            )}
                          />
                          <Route
                            path="report-issue"
                            Component={(props) => (
                              <ReportBug reloadData={reloadFromFirebase} />
                            )}
                          />
                          <Route
                            path="notification_preferences"
                            Component={(props) => (
                              <NotificationPreferences
                                reloadData={reloadFromFirebase}
                              />
                            )}
                          />
                        </>
                  )}
                  <Route path="*" element={<NotFound status={status} />} />
                </Routes>
              )}
            </AccountsContext.Provider>
          </ClientsContext.Provider>
        </PermissionsContext.Provider>
      </CurrentUserContext.Provider>
    </BrowserRouter>
  )
}
