import { Card, Typography } from '@material-tailwind/react'
import StandardLayout from '../components/StandardLayout'

export default function About(props: { reloadData }) {
  return (
    <StandardLayout title="About" reloadData={props.reloadData}>
      <Card placeholder="." className="flex h-full w-full flex-row rounded-lg">
        <div className="flex-coll h-full w-full p-4">
          <div className="flex w-full justify-center">
            <div className="w-fit flex-col">
              <div className="w-fit">
                <Typography variant="h4" placeholder={'.'}>
                  What is Orbit?
                </Typography>
                <hr className=" border-2 border-blue-800"></hr>
              </div>
              <div className="w-fit py-6">
                <Typography variant="paragraph" placeholder={'.'}>
                  &emsp;The goal of Orbit is to continue our tradition of
                  excellent customer service
                  <br />
                  through faster communication and clear organization. Orbit's
                  primary task is
                  <br />
                  to track crucial client information and assignments. Allowing
                  our team to
                  <br />
                  continue to focus on delivering results to our cleints and
                  forgo time intensive
                  <br />
                  record keeping.
                  <br />
                  &emsp;This codebase is live but will have frequent additions
                  and patches. Below is a
                  <br />
                  record of the most recent changes and additions as this tool
                  evolves to our needs.
                  <br />
                  I hope this tool proves useful to you!
                  <br />- James Metz
                </Typography>
              </div>
            </div>
          </div>
          <div className="p-6">
            <div className="w-fit">
              <Typography variant="h4" placeholder={'.'}>
                Release Notes
              </Typography>
              <hr className="border-2 border-blue-800"></hr>
            </div>
            <div className="w-2/3 p-2">
              <div className="w-full rounded-lg border-2 p-4">
                <div className="flex w-full justify-between">
                  <Typography placeholder={'.'} variant="lead">
                    1.1.0&emsp;-&emsp;UPCs and Kompass!
                  </Typography>

                  <Typography placeholder={'.'} variant="paragraph">
                    04/05/2024
                  </Typography>
                </div>

                <hr className="border-1.5 border-green-400"></hr>
                <Typography
                  variant="paragraph"
                  placeholder={'.'}
                  className="pt-2"
                >
                  This update contained some features I am really excited
                  about and these additions will continue to be improved in the
                  coming weeks.
                  <br />
                  &emsp;&emsp;- Kompass Calendar
                  <br />
                  &emsp;&emsp;&emsp;&emsp;(The calendar can be updated by
                  organizers when it changes.
                  <br />
                  &emsp;&emsp;&emsp;&emsp;This will be built on in the future,
                  but for now this can
                  <br />
                  &emsp;&emsp;&emsp;&emsp;allow for faster and more convient
                  access.)
                  <br />
                  &emsp;&emsp;- UPC Lists
                  <br />
                  &emsp;&emsp;&emsp;&emsp;(Based on a current list of UPCs I
                  obtained, I have added a serious
                  <br />
                  &emsp;&emsp;&emsp;&emsp;quantity of upc lists which will be
                  made editable soon!)
                  <br />
                  &emsp;&emsp;- Misc. Bug Fixes and UI Improvements
                  <br />
                  &emsp;&emsp;&emsp;&emsp;(The changes to the home screen clean
                  up the My Clients display <br />
                  &emsp;&emsp;&emsp;&emsp;a bit and allows for better
                  readability on the Change Logs.
                  <br />
                  &emsp;&emsp;&emsp;&emsp;Additionally, the client page has been
                  cleaned up for privacy and ease of access.)
                </Typography>
              </div>
            </div>
            <div className="w-2/3 p-2">
              <div className="w-full rounded-lg border-2 p-4">
                <div className="flex w-full justify-between">
                  <Typography placeholder={'.'} variant="lead">
                    1.0.2&emsp;-&emsp;Bug Fixes and Misc Improvements
                  </Typography>

                  <Typography placeholder={'.'} variant="paragraph">
                    03/19/2024
                  </Typography>
                </div>

                <hr className="border-1.5 border-green-400"></hr>
                <Typography
                  variant="paragraph"
                  placeholder={'.'}
                  className="pt-2"
                >
                  &emsp;There have been some minor changes made to solve issues
                  found during testing:
                  <br />
                  &emsp;&emsp;- Adding Assistants to Export Builder
                  <br />
                  &emsp;&emsp;&emsp;&emsp;(Additionally there have been
                  improvements to prevent empty column issues,
                  <br />
                  &emsp;&emsp;&emsp;&emsp;however these bugs have not been
                  completely eliminated and more work on this
                  <br />
                  &emsp;&emsp;&emsp;&emsp;feature will be required.)
                  <br />
                  &emsp;&emsp;- Improved Reloads from Firebase
                  <br />
                  &emsp;&emsp;&emsp;&emsp;(This should increase how quickly
                  changes are made to the UI,
                  <br />
                  &emsp;&emsp;&emsp;&emsp;by refreshing from the db more
                  frequently.)
                  <br />
                  &emsp;&emsp;- Misc. Bug Fixes
                  <br />
                  &emsp;&emsp;&emsp;&emsp;(There were a handful of other small
                  changes made, including <br />
                  &emsp;&emsp;&emsp;&emsp;removing scroll bars from the recent
                  changes section of the home page.)
                </Typography>
              </div>
            </div>
            <div className="w-2/3 p-2">
              <div className="w-full rounded-lg border-2 p-4">
                <div className="flex w-full justify-between">
                  <Typography placeholder={'.'} variant="lead">
                    1.0.1&emsp;-&emsp;Minor Additions
                  </Typography>

                  <Typography placeholder={'.'} variant="paragraph">
                    02/02/2024
                  </Typography>
                </div>

                <hr className="border-1.5 border-green-400"></hr>
                <Typography
                  variant="paragraph"
                  placeholder={'.'}
                  className="pt-2"
                >
                  &emsp;With some recent reccomendations I have added a few
                  smaller features:
                  <br />
                  &emsp;&emsp;- Marketing Tracker Fields
                  <br />
                  &emsp;&emsp;&emsp;&emsp;(Allows marketing team to track some{' '}
                  <br />
                  &emsp;&emsp;&emsp;&emsp;key details and export them via a new
                  canned report:
                  <br />
                  &emsp;&emsp;&emsp;&emsp;"Marketing Tracker")
                  <br />
                  &emsp;&emsp;- Improvements to Potential Client Display
                  <br />
                  &emsp;&emsp;&emsp;&emsp;(On the client list page, a blue
                  <br />
                  &emsp;&emsp;&emsp;&emsp;"potential" tag is now displayed for
                  client status if
                  <br />
                  &emsp;&emsp;&emsp;&emsp;potential_client is True for that
                  account.)
                  <br />
                  &emsp;&emsp;- Misc. Bug Fixes
                  <br />
                  &emsp;&emsp;&emsp;&emsp;(There were a handful of other small
                  <br />
                  &emsp;&emsp;&emsp;&emsp;features that were tweaked, including
                  the spacing of payment
                  <br />
                  &emsp;&emsp;&emsp;&emsp;blocks on client pages.)
                  <br />
                </Typography>
              </div>
            </div>
            <div className="w-2/3 p-2">
              <div className="w-full rounded-lg border-2 p-4">
                <div className="flex w-full justify-between">
                  <Typography placeholder={'.'} variant="lead">
                    1.0.0&emsp;-&emsp;Initial Release
                  </Typography>

                  <Typography placeholder={'.'} variant="paragraph">
                    01/28/2024
                  </Typography>
                </div>

                <hr className="border-1.5 border-green-400"></hr>
                <Typography
                  variant="paragraph"
                  placeholder={'.'}
                  className="pt-2"
                >
                  &emsp;This initial Release included the initial database
                  launch and some headline features:
                  <br />
                  &emsp;&emsp;- Custom Exports Tab
                  <br />
                  &emsp;&emsp;&emsp;&emsp;(Allows you to export a csv based on
                  the fields and filters of your choosing for faster access to
                  data.)
                  <br />
                  &emsp;&emsp;- Potential Client Tag
                  <br />
                  &emsp;&emsp;&emsp;&emsp;(On the client page you can choose to
                  mark a client as potential, indicating they are in the
                  interview stage.)
                  <br />
                  &emsp;&emsp;- Logins
                  <br />
                  &emsp;&emsp;&emsp;&emsp;(On the client page credentials for
                  Stratum, M6 etc. can be stored for rapid access.)
                  <br />
                  &emsp;&emsp;- Broker Portal Credentials
                  <br />
                  &emsp;&emsp;&emsp;&emsp;(The settings page for organizers can
                  be used to pick the default credentials of the broker portal.
                  <br />
                  &emsp;&emsp;&emsp;&emsp;For all clients with broker portal
                  selected in the login field, these presets will be populated.)
                  <br />
                  &emsp;&emsp;- Comments and Documents
                  <br />
                  &emsp;&emsp;&emsp;&emsp;(Two new fields have been added to the
                  Client Page, which are free form and not <br />
                  &emsp;&emsp;&emsp;&emsp;currently exportable. 'Documents' can
                  support essentially any file format)
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </StandardLayout>
  )
}
