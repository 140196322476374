import {
  Card,
  Typography,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from '@material-tailwind/react'
import StandardLayout from '../../components/StandardLayout'
import PermissionsTab from './PermissionsTab'
import AddPaymentsTab from './AddPaymentsTab'
import PresetsTab from './PresetsTab'
import PrevPaymentsTab from './PrevPaymentsTab'
import { useContext } from 'react'
import { PermissionsContext } from '../../contexts'

export function Settings(props: { reloadData: Function }) {
  const permissions = useContext(PermissionsContext)

  return (
    <StandardLayout title="Settings" reloadData={props.reloadData}>
      <Card
        placeholder="."
        className="flex h-full w-fit min-w-full flex-col rounded-lg"
      >
        <Typography placeholder={'Settings Page'} variant="h4" className="p-6">
          Settings
        </Typography>
        <hr className="border border-blue-700"></hr>
        <Tabs value="permission" orientation="vertical">
          <TabsHeader placeholder={'.'}>
            {permissions.edit_presets && (
              <Tab
                placeholder={'.'}
                key={'permission'}
                value={'permission'}
                className="h-15 p-4"
              >
                Permissisons
              </Tab>
            )}
            {permissions.edit_permissions && (
              <Tab
                placeholder={'.'}
                key={'presets'}
                value={'presets'}
                className="h-15 p-4"
              >
                Presets
              </Tab>
            )}
            {permissions.view_payments && (
              <Tab
                placeholder={'.'}
                key={'prev-payment'}
                value={'prev-payment'}
                className="h-15 p-4"
              >
                Previous Payments
              </Tab>
            )}
            {permissions.edit_payments && (
              <Tab
                placeholder={'.'}
                key={'add-payment'}
                value={'add-payment'}
                className="h-15 p-4"
              >
                Add Payments
              </Tab>
            )}
          </TabsHeader>
          <TabsBody placeholder={'.'} className="w-full">
            <TabPanel
              key={'permission'}
              value={'permission'}
              className="w-full"
            >
              <PermissionsTab reloadData={props.reloadData} />
            </TabPanel>
            <TabPanel key={'presets'} value={'presets'} className="w-full">
              <PresetsTab />
            </TabPanel>
            <TabPanel
              key={'prev-payment'}
              value={'prev-payment'}
              className="h-full w-full"
            >
              <PrevPaymentsTab />
            </TabPanel>
            <TabPanel
              key={'add-payment'}
              value={'add-payment'}
              className="w-full"
            >
              <AddPaymentsTab />
            </TabPanel>
          </TabsBody>
        </Tabs>
      </Card>
    </StandardLayout>
  )
}
