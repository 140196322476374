import { account } from "../../types/account"


export default function ensureAccountType(input_obj) {
  var account_template: account = {
    id: "",
    email:  "",
    internal: false,
    name: ""
  }

  Object.keys(input_obj).forEach((key)=>{
      account_template[key] = input_obj[key]
  })
  return account_template
}
