import { doc, setDoc } from "firebase/firestore";
import { account } from "../types/account";
import { db } from "../firebase";

export default async function formatEmails(accounts: account[]) {
    function containsUpperCase(str: string) {
        return str !== str.toLowerCase();
    }

    var array_of_accounts: account[] = []
    accounts.forEach((account)=>{ // right now a huge amount of accounts are camelcased because kris was working 
        if (account.email.includes(" ") || containsUpperCase(account.email)) {
            array_of_accounts.push(account)
        }
    })
    
    for (let i = 0; i < array_of_accounts.length; i++) {
        const account = array_of_accounts[i]
        var new_account = JSON.parse(JSON.stringify(account))
        new_account.email = account.email.replace(" ", "").toLowerCase()
        
        await setDoc(doc(db, "accounts", account.id), new_account)
    }
}