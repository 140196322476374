import { Link } from 'react-router-dom'
import { client } from '../types/client'
import { Card, Typography } from '@material-tailwind/react'
import { ClipboardIcon } from '@heroicons/react/24/outline'

export default function ClientCard(props: {
  client: client
  pinned: boolean
}) {
  const client = props.client

  return (
    <div className="h-fit w-fit p-2">
      <Link to={'/view/client#' + props.client.id}>
        <Card
          placeholder="."
          className="h-fit w-44 border border-gray-300 p-2 shadow-md"
        >
          {client.logo ? (
            <div className="flex justify-center">
              <img
                src={client?.logo}
                alt="Client Logo"
                className="h-20 w-20 object-scale-down"
              />
              <div className="py-2">
                <hr />
              </div>
            </div>
          ) : (
            <div className="flex justify-center">
              <ClipboardIcon className="h-20 w-20" />
              <div className="py-2">
                <hr />
              </div>
            </div>
          )}
          <Typography placeholder=".">{client.name}</Typography>
        </Card>
      </Link>
    </div>
  )
}
