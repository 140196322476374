import { deleteDoc, doc, getDoc, setDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { db } from '../firebase'
import Loading from './Loading'
import { upcDoc } from '../types/upcDoc'
import {
  Button,
  Chip,
  IconButton,
  Switch,
  Typography,
} from '@material-tailwind/react'
import {
  ArrowUpTrayIcon,
  DocumentDuplicateIcon,
  PencilIcon,
} from '@heroicons/react/24/outline'
import { Navigate } from 'react-router'

export default function UPCList({ client }) {
  const [upcDoc, setUpcDoc] = useState<upcDoc | undefined>()
  const [categoriesToggle, setCategoriesToggle] = useState(true)
  const [docPulled, setDocPulled] = useState<boolean | undefined>(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [filter, setFilter] = useState('')
  const [redirectToEdit, setRedirectToEdit] = useState(false)

  async function fetchUPCs() {
    const doc_ref_id = doc(db, 'upcDocs', client.id)
    await getDoc(doc_ref_id).then((doc) => {
      if (doc.exists()) {
        console.log(doc.data().upcs)
        setUpcDoc(doc.data().upcs)
        setDocPulled(true)
        cleanOldUPCDoc(client.file_id)
      } else {
        fetchByFileID()
      }
    })
  }
  async function cleanOldUPCDoc(file_id) {
    // this is only a temporarily important function. 
    // essentially the initial upload of upcs was done based on file_ids, but I need them based on client id.
    // so in the event that a client_id based upcDoc is present the file id version should be deleted. 
    // All new upc docs are saved based on client_id so over time this should never exceute.
    try {
      await deleteDoc(doc(db, "upcDocs", file_id))
    }catch {
      console.log('There was no file_id version of upcDoc found or there was an error deleting it.')
    } 
  }
  async function fetchByFileID() {
    const doc_ref_file_id = doc(db, 'upcDocs', client.file_id)
    await getDoc(doc_ref_file_id).then((doc) => {
      if (doc.exists()) {
        setDocPulled(true)
        setUpcDoc(doc.data().upcs)
      } else {
        setDocPulled(true)
      }
    })
  }
  function headers() {
    if (categoriesToggle) {
      return [
        'UPC',
        'Case UPC',
        'In Kroger',
        'In Circana',
        'Dept.',
        'Comm',
        'Sub Comm',
        'Sub Comm Code',
        'Description',
      ]
    } else {
      return [
        'UPC',
        'Case UPC',
        'In Kroger',
        'In Circana',
        'Dept.',
        'Aisle',
        'Category',
        'Sub Category',
        'Description',
      ]
    }
  }
  function infoChip(input: boolean) {
    return (
      <Typography
        placeholder="."
        variant="small"
        color="blue-gray"
        className="font-normal flex justify-center"
      >
        {input ? (
          <Chip variant="ghost" color="green" size="sm" value="Yes" />
        ) : (
          <Chip variant="ghost" color="red" size="sm" value="No" />
        )}
      </Typography>
    )
  }
  function copyUPCsToClip() {
    if (upcDoc) {
      var upcs = Object.keys(upcDoc).join(', ')
      navigator.clipboard.writeText(upcs)
    } else {
      alert('There was an error copying UPCs.')
    }
  }
  function copyCaseUPCsToClip() {
    if (upcDoc) {
      var upcs: string[] = []

      Object.keys(upcDoc).forEach((upc) => {
        const case_UPCs = upcDoc[upc].case_UPC
        if (case_UPCs) {
          upcs.concat(case_UPCs)
        }
      })
      const upc_string = upcs.join(', ')
      navigator.clipboard.writeText(upc_string)
    } else {
      alert('There was an error copying UPCs.')
    }
  }
  function toggleKrogerFilter() {
    if (filter === 'kroger') {
      setFilter('not_kroger')
    } else if (filter === 'not_kroger') {
      setFilter('')
    } else {
      setFilter('kroger')
    }
  }
  function toggleCircanaFilter() {
    if (filter === 'circana') {
      setFilter('not_circana')
    } else if (filter === 'not_circana') {
      setFilter('')
    } else {
      setFilter('circana')
    }
  }
  function redirectToEditPage() {
    setRedirectToEdit(true)
  }
  function toggleCategories() {
    setCategoriesToggle(!categoriesToggle)
  }
  function sortUPCs(upc_array: string[]) {
    if (filter === 'kroger') {
      return upc_array
        .sort((a, b) => {
          return a.localeCompare(b)
        })
        .filter((upc) => {
          if (upcDoc) {
            return upcDoc[upc].in_kroger == true
          }
        })
    } else if (filter === 'not_kroger') {
      return upc_array
        .sort((a, b) => {
          return a.localeCompare(b)
        })
        .filter((upc) => {
          if (upcDoc) {
            return upcDoc[upc].in_kroger == false
          }
        })
    } else if (filter === 'circana') {
      return upc_array
        .sort((a, b) => {
          return a.localeCompare(b)
        })
        .filter((upc) => {
          if (upcDoc) {
            return upcDoc[upc].in_circana == true
          }
        })
    } else if (filter === 'not_circana') {
      return upc_array
        .sort((a, b) => {
          return a.localeCompare(b)
        })
        .filter((upc) => {
          if (upcDoc) {
            return upcDoc[upc].in_circana == false
          }
        })
    } else {
      return upc_array.sort((a, b) => {
        return a.localeCompare(b)
      })
    }
  }
  function filterValuesByToggle() {
    if (categoriesToggle) {
      return [
        'kr_dept',
        'kr_comm',
        'kr_sub_comm',
        'kr_sub_comm_code',
        'description',
      ]
    } else {
      return [
        'cir_dept',
        'cir_aisle',
        'cir_category',
        'cir_sub_category',
        'description',
      ]
    }
  }

  useEffect(() => {
    if (firstLoad) {
      fetchUPCs()
      setFirstLoad(false)
    }
  })

  const table_cell_classes = ' border border-gray-400 py-0 px-1 text-black'

  return (
    <div>
      {redirectToEdit && (
        <Navigate to={'/edit/client/upcs#' + client.id} replace />
      )}
      {docPulled ? (
        <div>
          {upcDoc ? (
            <div className="flex flex-col">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row align-middle">
                  <div className="p-2">
                    {categoriesToggle ? (
                      <div className="text-black">Kroger </div>
                    ) : (
                      <div className="text-black">Circana </div>
                    )}
                  </div>
                  <div className="p-2">
                    <Switch crossOrigin={'.'} onClick={toggleCategories} />
                  </div>
                </div>
                <div className="px-2">
                  <IconButton
                    placeholder={'.'}
                    onClick={redirectToEditPage}
                    variant="text"
                  >
                    <PencilIcon className="h-6 w-6" />
                  </IconButton>
                </div>
              </div>
              <div className="rounded-t-md overflow-clip">
                <table className="w-full min-w-fit table-auto text-left">
                  <thead>
                    <tr>
                      {headers().map((title) => {
                        if (title === 'UPC') {
                          return (
                            <th
                              className="border-b border-black bg-blue-gray-50 p-2"
                              onClick={copyUPCsToClip}
                            >
                              <div className="flex flex-row justify-around">
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none text-black"
                                >
                                  {title}
                                </Typography>
                                <IconButton
                                  placeholder={'.'}
                                  className="p-0 h-6 w-6"
                                  variant="text"
                                >
                                  <DocumentDuplicateIcon className="w-4 h-4" />
                                </IconButton>
                              </div>
                            </th>
                          )
                        } else if (title === 'Case UPC') {
                          return (
                            <th
                              className="border-b border-black bg-blue-gray-50 p-2"
                              onClick={copyCaseUPCsToClip}
                            >
                              <div className="flex flex-row justify-around">
                                <Typography
                                  placeholder="."
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none text-black"
                                >
                                  {title}
                                </Typography>
                                <IconButton
                                  placeholder={'.'}
                                  className="p-0 h-6 w-6"
                                  variant="text"
                                >
                                  <DocumentDuplicateIcon className="w-4 h-4" />
                                </IconButton>
                              </div>
                            </th>
                          )
                        } else if (title === 'In Kroger') {
                          return (
                            <th
                              className="border-b border-black bg-blue-gray-50 p-2"
                              onClick={toggleKrogerFilter}
                            >
                              <Typography
                                placeholder="."
                                variant="small"
                                color="blue-gray"
                                className="font-normal leading-none text-black"
                              >
                                {title}
                              </Typography>
                            </th>
                          )
                        } else if (title === 'In Circana') {
                          return (
                            <th
                              className="border-b border-black bg-blue-gray-50 p-2"
                              onClick={toggleCircanaFilter}
                            >
                              <Typography
                                placeholder="."
                                variant="small"
                                color="blue-gray"
                                className="font-normal leading-none text-black"
                              >
                                {title}
                              </Typography>
                            </th>
                          )
                        } else {
                          return (
                            <th className="border-b border-black bg-blue-gray-50 p-2">
                              <Typography
                                placeholder="."
                                variant="small"
                                color="blue-gray"
                                className="font-normal leading-none text-black"
                              >
                                {title}
                              </Typography>
                            </th>
                          )
                        }
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {upcDoc && (
                      <>
                        {sortUPCs(Object.keys(upcDoc)).map((upc) => (
                          <tr>
                            <td className={table_cell_classes}>{upc}</td>
                            <td className={table_cell_classes}>
                              {upcDoc[upc].case_UPC}
                            </td>
                            <td className={table_cell_classes}>
                              {infoChip(upcDoc[upc].in_kroger)}
                            </td>
                            <td className={table_cell_classes}>
                              {infoChip(upcDoc[upc].in_circana)}
                            </td>
                            {filterValuesByToggle().map((key) => (
                              <>
                                {upcDoc[upc][key] ? (
                                  <td className={table_cell_classes}>
                                    {upcDoc[upc][key]}
                                  </td>
                                ) : (
                                  <td className={table_cell_classes}></td>
                                )}
                              </>
                            ))}
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="p-12 flex flex-row justify-center">
              <Button placeholder={''} onClick={redirectToEditPage}>
                Add UPCs
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className="p-10">
          <Loading />
        </div>
      )}
    </div>
  )
}
