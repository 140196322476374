import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Avatar,
  Card,
  IconButton,
  Typography,
} from '@material-tailwind/react'
import { account } from '../../types/account'
import { client } from '../../types/client'
import {
  ClientsContext,
  CurrentUserContext,
  PermissionsContext,
} from '../../contexts'
import { useContext, useEffect, useState } from 'react'
import { permission } from '../../types/permission'
import { Link } from 'react-router-dom'
import { PencilIcon, UserIcon } from '@heroicons/react/24/outline'
import Loading from '../../components/Loading'
import ClientCard from '../../components/CleintCard'
import collectClientDocsWithAccountRef from '../../functions/collectClientDocsWIthAccountRef'
import collectContactIdsFromClient from '../../functions/collectContactIdsFromClient'

export default function AccountView(props: {
  account: account
  clients_with_ref: client[]
}) {
  const permissions: permission = useContext(PermissionsContext)
  const currentUser: account = useContext(CurrentUserContext)
  const [loading, setLoading] = useState(true)
  const [firstLoad, setFirstLoad] = useState(true)
  const [accordion, setAccordion] = useState({})

  const [usersContactsFromClients, setUsersContactFromClients] = useState<
    string[]
  >([])
  const clients = useContext(ClientsContext)
  const [allRefClientIDsByTitle, setAllRefClientIDsByTitle] = useState({})

  function getContactIDsForAccountFromClients(account_id, clients) {
    var clients_with_ref: client[] = collectClientDocsWithAccountRef(
      clients,
      account_id,
      true
    )

    var contacts_for_this_account: string[] = []

    clients_with_ref.forEach((client_doc) => {
      const contacts_from_this_client = collectContactIdsFromClient(client_doc)
      contacts_for_this_account = contacts_for_this_account.concat(
        contacts_from_this_client
      )
    })
    setUsersContactFromClients(contacts_for_this_account)
  }
  function setClientLists() {
    var ref_client_ids_by_title = {}
    props.clients_with_ref.forEach((client: client) => {
      const contacts = client.contacts
      const roles = client.roles

      Object.keys(contacts).forEach((title) => {
        if (contacts[title]) {
          if (contacts[title].includes(props.account.id)) {
            if (title in ref_client_ids_by_title) {
              ref_client_ids_by_title[title].push(client.id)
            } else {
              ref_client_ids_by_title[title] = [client.id]
            }
          }
        }
      })
      Object.keys(roles).forEach((title) => {
        if (roles[title]) {
          if (roles[title].includes(props.account.id)) {
            if (title in ref_client_ids_by_title) {
              ref_client_ids_by_title[title].push(client.id)
            } else {
              ref_client_ids_by_title[title] = [client.id]
            }
          }
        }
      })
    })

    setAllRefClientIDsByTitle(ref_client_ids_by_title)
    var all_titles = Object.keys(ref_client_ids_by_title)

    var accordion_obj = {}

    all_titles = sortTitles(all_titles)

    all_titles.forEach((title) => {
      accordion_obj[title] = true
    })
    setAllTitlesInOrder(all_titles)
    setAccordion(accordion_obj)
  }
  const [allTitlesInOrder, setAllTitlesInOrder] = useState<string[]>([])
  function sortTitles(all_titles: string[]) {
    const title_refs = {
      VP: 0,
      Director: 1,
      'Business Manager': 2,
      'Assistant Business Manager': 3,
      'Insights Manager': 4,
      'Assistant Insights Manager': 5,
      Orders: 6,
      Claims: 7,
      Marketing: 8,
      'Primary Contact': 9,
      'Marketing or Creative Contact': 10,
      'Billing Contact': 11,
      'Sales Contact': 12,
      'Operations Contact': 13,
      'Invoice Contact': 14,
    }

    return all_titles.sort((a, b) => {
      if (a in title_refs) {
        if (a in title_refs && b in title_refs) {
          return title_refs[a] - title_refs[b] // both are proper titles.
        } else {
          return -1 // B does not have proper title.
        }
      } else {
        if (b in title_refs) {
          return 1 // A does not have proper title.
        } else {
          return a.localeCompare(b) // Neither has proper title and alphabetical order works fine.
        }
      }
    })
  }

  useEffect(() => {
    if (permissions && usersContactsFromClients) {
      setLoading(false)
    }
    if (firstLoad && clients && currentUser) {
      setClientLists()
      getContactIDsForAccountFromClients(currentUser.id, props.clients_with_ref)
      setFirstLoad(false)
    }
  })

  function toggleAccordion(title) {
    setAccordion({ ...accordion, [title]: !accordion[title] })
  }
  function sortClientsByName(clients: client[]) {
    if (clients && clients.length > 1) {
      const clients_in_order = clients.sort((a, b) =>
        a.name.localeCompare(b.name)
      )
      return clients_in_order
    } else {
      return clients
    }
  }
  function getCleintsFromIDs(client_ids, clients) {
    var client_docs: client[] = []
    if (clients && clients.length > 0 && client_ids && client_ids.length > 0) {
      for (let i = 0; i < clients.length; i++) {
        if (clients[i]) {
          const client = clients[i]
          if (client_ids.includes(client.id)) {
            client_docs.push(client)
          }
        }
      }
    }
    return client_docs
  }

  return (
    <Card placeholder="." className="flex w-full flex-col p-4">
      {loading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <>
          <div className="flex flex-row justify-center">
            <div className="flex-ro flex p-5">
              <div className="">
                {props.account.avatar !== undefined &&
                props.account.avatar !== '' ? (
                  <Avatar
                    placeholder="Profile Photo not found."
                    src={props.account.avatar}
                    className="h-40 w-40 p-2"
                  />
                ) : (
                  <UserIcon className="h-40 w-40 p-2" />
                )}
              </div>
              <div className="flex w-2/3 flex-grow flex-col justify-center pl-10 pt-3">
                <Typography placeholder="." variant="h5" color="blue-gray">
                  {props.account.name}
                </Typography>
                <Typography placeholder="." variant="small" color="blue-gray">
                  Work: {props.account?.phone}
                </Typography>
                <Typography placeholder="." variant="small" color="blue-gray">
                  Cell: {props.account['Cell Phone']}
                </Typography>
                <Typography placeholder="." variant="small" color="blue-gray">
                  {props.account.email}
                </Typography>
                <Typography placeholder="." variant="small" color="blue-gray">
                  {props.account.title}
                </Typography>
              </div>
            </div>
            <div className="p-2">
              {(permissions.edit_accounts === true ||
                props.account.id === currentUser.id ||
                (permissions.edit_my_client_contacts !== undefined &&
                  permissions.edit_my_client_contacts === true &&
                  usersContactsFromClients.includes(props.account.id))) && (
                <Link to={'/edit/account#' + String(props.account.id)}>
                  <IconButton
                    placeholder="."
                    variant="text"
                    className="h-20 w-20"
                  >
                    <PencilIcon className="h-8 w-8"></PencilIcon>
                  </IconButton>
                </Link>
              )}
            </div>
          </div>
          <div className="flex h-fit w-full flex-col justify-center rounded-md">
            {props.clients_with_ref.length > 0 && (
              <div>
                <div className="flex flex-row flex-wrap">
                  {allTitlesInOrder.map((title, index) => {
                    return (
                      <div
                        className="w-full flex flex-col"
                        key={String(index) + '_clients_titled_section'}
                      >
                        <Accordion
                          placeholder={''}
                          open={accordion[title] && accordion[title] === true}
                        >
                          <AccordionHeader
                            placeholder={''}
                            onClick={() => toggleAccordion(title)}
                          >
                            <div className="p-2 w-full">
                              <Typography placeholder={'.'} variant="h4">
                                {title}
                              </Typography>
                            </div>
                          </AccordionHeader>
                          <AccordionBody>
                            <div className="flex flex-row flex-wrap">
                              {sortClientsByName(
                                getCleintsFromIDs(
                                  allRefClientIDsByTitle[title],
                                  clients
                                )
                              ).map((client, sub_index) => {
                                if (client) {
                                  return (
                                    <ClientCard
                                      client={client}
                                      pinned={false}
                                      key={sub_index}
                                    />
                                  )
                                } else {
                                  return <></>
                                }
                              })}
                            </div>
                          </AccordionBody>
                        </Accordion>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </Card>
  )
}
