import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

type args = {
  email: string;
};
type response = [Boolean, Boolean];

export default async function createAccount(arr: args) {
  try {
    console.log("input email in create account:",arr.email)
    const docRef = await addDoc(collection(db, "create_account_requests"), {
      email: arr.email,
    });
    setTimeout(async () => {
      var response_found = false
      var response_status = false
      await getDoc(doc(db, "create_account_responses", docRef.id)).then((docRef) => {
        if (docRef.exists()) {
          const response_from_fb = docRef.data();
          response_found = true;
          response_status = response_from_fb.status
        } else {
          response_found = false
          response_status = false
        }
        if (response_found === true) {
          if (response_status === true) {
            alert("Account was successfully created! You must refresh the page before adding permissions to their account.")
          } else {
            alert("Account Creation failed. The user likely already has an account or there is an issue with their email. Please reload and try again!")
          }
        } else {
          alert("Account Creation failed. Please Reload the page and try again!")
        }
      });
    }, 5000)
  } catch (err) {
    console.log(err)
    alert([false, false])
  }
}
