import {
  Button,
  Card,
  Input,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Textarea,
  Typography,
} from '@material-tailwind/react'
import StandardLayout from '../components/StandardLayout'
import { useContext, useEffect, useState } from 'react'
import { CurrentUserContext } from '../contexts'
import { account } from '../types/account'
import { addDoc, collection, doc, setDoc } from 'firebase/firestore'
import { db } from '../firebase'
import { Navigate } from 'react-router'

export default function ReportBug(props: { reloadData }) {
  const [formComplete, setFormComplete] = useState(false)
  const [formContent, setFormContent] = useState({
    subject: '',
    description: '',
    return_address: '',
    submitted: new Date(),
  })

  const [firstLoad, setFirstLoad] = useState(true)
  const account: account | undefined = useContext(CurrentUserContext)
  useEffect(() => {
    if (
      formContent.subject !== '' &&
      formContent.description !== '' &&
      formContent.return_address !== ''
    ) {
      setFormComplete(true)
    } else {
      setFormComplete(false)
    }

    if (firstLoad && account) {
      setFormContent({ ...formContent, return_address: account.email })
      setFirstLoad(false)
    }
  })
  const [redirectHome, setRedirectHome] = useState(false)
  async function submit() {
    await addDoc(collection(db, 'bug-report'), formContent)
      .then(() => {
        alert('Issue Report was saved successfully!')
        setRedirectHome(true)
      })
      .catch(() =>
        alert(
          'There was an issue submitting your ticket. Please reload the page and try again.'
        )
      )
  }
  return (
    <StandardLayout title="Report Issue" reloadData={props.reloadData}>
      {redirectHome && <Navigate to="/" replace />}
      <Card placeholder="." className="flex h-full w-fit flex-row rounded-lg">
        <div className="flex flex-col h-full w-fit p-4 justify-around">
          <div className="w-fit flex flex-col">
            <Typography variant="h4" placeholder={'.'} className="w-full">
              Running into an Issue?
            </Typography>
            <hr className=" border-2 border-blue-800"></hr>
          </div>
          <div className="p-4">
            Below is a quick form to capture the details of your issue.
            <br />
            The more relavent details you can provide, the easier the issue will
            be to resolve.
            <br />
            Reporting bugs keeps this tool accurate and functional, thanks!
          </div>
          <div className="p-4">
            <div className="py-2">
              <Input
                crossOrigin="false"
                label="Subject"
                value={formContent.subject}
                onChange={(e) =>
                  setFormContent({ ...formContent, subject: e.target.value })
                }
              />
            </div>
            <div className="py-2">
              <Textarea
                label="Description"
                value={formContent.description}
                onChange={(e) =>
                  setFormContent({
                    ...formContent,
                    description: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <Input
                crossOrigin="false"
                label="Return Address"
                value={formContent.return_address}
                onChange={(e) =>
                  setFormContent({
                    ...formContent,
                    description: e.target.value,
                  })
                }
              />
            </div>
            <div className="py-6">
              {formComplete ? (
                <Button placeholder={'.'} color="green" onClick={submit}>
                  Submit
                </Button>
              ) : (
                <Button placeholder={'.'} color="green" disabled>
                  Submit
                </Button>
              )}
            </div>
          </div>
        </div>
      </Card>
    </StandardLayout>
  )
}
